var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-autocomplete',{style:({'max-width': (_vm.maxWidth + "px")}),attrs:{"color":_vm.color,"items":_vm.accountsSorted,"menu-props":{'contentClass': 'account-switcher'},"prepend-inner-icon":_vm.icon,"value":_vm.value,"flat":"","hide-details":"","item-text":"account_code","item-value":"customer_id"},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"color":_vm.listColor,"value":item.account_code,"two-line":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name)+_vm._s(item.impersonated ? '*' : ''))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.account_code))]),(item.impersonated)?_c('v-list-item-subtitle',[_vm._v("Logged in via WIMS")]):_vm._e()],1)],1)]}}],null,true)})],1)]}}])},[_vm._v(" Switch Account ")])}
var staticRenderFns = []

export { render, staticRenderFns }