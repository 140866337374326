import client from '@/http'

/**
 * Client Auth API endpoints
 */
export default {
    async login (data) {
        try {
            const response = await client.post('/auth/login', data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async logout () {
        try {
            return await client.get('/auth/logout')
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async me (data) {
        try {
            let headers = {}
            let token
            let customerId

            if ((token = (data?.token))) {
                headers['X-AUTH-TOKEN'] = token
            }

            if ((customerId = (data?.customerId))) {
                headers['X-AUTH-CUSTOMER-ID'] = customerId
            }

            const response = await client.get('/auth/me', {headers})

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async ping () {
        try {
            return await client.get('/ping')
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
