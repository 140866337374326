import routeNames from './route-names'

const AssignUser = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/modules/AssignUser.vue')
const CancelUserInvite = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/modules/CancelUserInvite')
const DisableAccess = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/modules/DisableAccess.vue')
const EnableAccess = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/modules/EnableAccess.vue')
const Disable2fa = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/modules/Disable2fa.vue')
const Extension = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/Extension.vue')
const ResendUserInvite = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/modules/ResendUserInvite.vue')
const SendPasswordReset = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/modules/SendPasswordReset')
const UnassignUser = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/modules/extension/modules/UnassignUser')

export default {
    path: ':extensionId',
    component: Extension,
    props: true,
    children: [
        {
            path: 'assign-user',
            name: routeNames.assignUser,
            component: AssignUser,
            props: true
        },
        {
            path: 'cancel-user-invite',
            name: routeNames.cancelUserInvite,
            component: CancelUserInvite,
            props: true
        },
        {
            path: 'enable',
            name: routeNames.enableAccess,
            component: EnableAccess,
            props: true
        },
        {
            path: 'disable',
            name: routeNames.disableAccess,
            component: DisableAccess,
            props: true
        },
        {
            path: 'disable2fa',
            name: routeNames.disable2fa,
            component: Disable2fa,
            props: true
        },
        {
            path: 'resend-user-invite',
            name: routeNames.resendUserInvite,
            component: ResendUserInvite,
            props: true
        },
        {
            path: 'send-password-reset',
            name: routeNames.sendPasswordReset,
            component: SendPasswordReset,
            props: true
        },
        {
            path: 'unassign-user',
            name: routeNames.unassignUser,
            component: UnassignUser,
            props: true
        }
    ]
}
