export const WALLBOARDS = 'WALLBOARDS'
export const WALLBOARDS_CREATE = 'WALLBOARDS_CREATE'
export const WALLBOARDS_UPDATE = 'WALLBOARDS_UPDATE'
export const WALLBOARDS_DUPLICATE = 'WALLBOARDS_DUPLICATE'
export const WALLBOARDS_REMOVE = 'WALLBOARDS_REMOVE'
export const WALLBOARDS_BULK_REMOVE = 'WALLBOARDS_BULK_REMOVE'

// Queues
export const WALLBOARD_QUEUES = 'WALLBOARD_QUEUES'
export const WALLBOARD_QUEUES_ADD = 'WALLBOARD_QUEUES_ADD'
export const WALLBOARD_QUEUES_CREATE = 'WALLBOARD_QUEUES_CREATE'
export const WALLBOARD_QUEUES_SETTINGS = 'WALLBOARD_QUEUES_SETTINGS'
export const WALLBOARD_QUEUES_REMOVE = 'WALLBOARD_QUEUES_REMOVE'
export const WALLBOARD_QUEUES_BULK_REMOVE = 'WALLBOARD_QUEUES_BULK_REMOVE'

// Cards
export const WALLBOARD_CARDS = 'WALLBOARD_CARDS'
export const WALLBOARD_CARDS_CREATE = 'WALLBOARD_CARDS_CREATE'
export const WALLBOARD_CARDS_UPDATE = 'WALLBOARD_CARDS_UPDATE'
export const WALLBOARD_CARDS_REMOVE = 'WALLBOARD_CARDS_REMOVE'

export default {
    list: WALLBOARDS,
    create: WALLBOARDS_CREATE,
    update: WALLBOARDS_UPDATE,
    duplicate: WALLBOARDS_DUPLICATE,
    remove: WALLBOARDS_REMOVE,
    bulkRemove: WALLBOARDS_BULK_REMOVE,
    queues: {
        list: WALLBOARD_QUEUES,
        add: WALLBOARD_QUEUES_ADD,
        create: WALLBOARD_QUEUES_CREATE,
        remove: WALLBOARD_QUEUES_REMOVE,
        bulkRemove: WALLBOARD_QUEUES_BULK_REMOVE,
        settings: WALLBOARD_QUEUES_SETTINGS
    },
    cards: {
        list: WALLBOARD_CARDS,
        create: WALLBOARD_CARDS_CREATE,
        update: WALLBOARD_CARDS_UPDATE,
        remove: WALLBOARD_CARDS_REMOVE
    }
}
