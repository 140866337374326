import 'core-js/stable'
// Vue Core
import router from './router'
import store from './store'
import Vue from 'vue'
// Components
import App from './App.vue'
// Mixins
import BaseRenderErrorMixin from '@core/mixins/core/base-render-error.mixin'
// Plugins
import SentryPlugin from '@core/plugins/sentry'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'

const isCypress = window.Cypress

Vue.config.silent = Boolean(isCypress)
Vue.config.productionTip = false
Vue.config.devtools = !isCypress && process.env.VUE_APP_ENV !== 'production'

Vue.mixin(BaseRenderErrorMixin)
Vue.use(VueMeta)

let sentry = process.env.VUE_APP_SENTRY_URL

// Configure Sentry if we are in a production environment
if (sentry) {
    Vue.use(SentryPlugin(sentry))
}

const vm = new Vue({
    router,
    sentry,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

if (isCypress) {
    window.app = vm
}
