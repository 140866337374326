import routeNames from './route-names'

const Queues = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/Queues')
const ManageQueue = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/ManageQueue')
const ManageQueueSettings = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/ManageQueueSettings')
const RemoveQueues = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/RemoveQueues')

// Agents
const QueueAgents = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/modules/agents/QueueAgents')
const AddQueueAgents = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/modules/agents/AddQueueAgents')
const ManageAgent = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/modules/agent/ManageAgent')
const RemoveQueueAgents = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/modules/agents/RemoveQueueAgents')

export default {
    path: 'queues',
    name: routeNames.list,
    meta: {title: 'Queues'},
    component: Queues,
    children: [
        {
            path: 'create',
            name: routeNames.create,
            meta: {title: 'Enable Queue'},
            props: (route) => ({
                ...route.params,
                redirectOnClose: {name: routeNames.list},
                redirectOnCreate: {name: routeNames.settings}
            }),
            component: ManageQueue
        },
        {
            path: 'update/:queueUuid',
            name: routeNames.update,
            meta: {title: 'Update Queue'},
            props: (route) => ({
                ...route.params,
                redirectOnClose: {name: routeNames.list}
            }),
            component: ManageQueue
        },
        {
            path: 'settings/:queueUuid',
            name: routeNames.settings,
            meta: {title: 'Update Queue Settings'},
            props: (route) => ({
                ...route.params,
                redirect: {
                    name: routeNames.list
                }
            }),
            component: ManageQueueSettings
        },
        {
            path: 'remove/multiple',
            name: routeNames.bulkRemove,
            meta: {title: 'Remove Queues'},
            props: (route) => ({
                ...route.params,
                bulk: true
            }),
            component: RemoveQueues
        },
        {
            path: 'remove/:queueUuid',
            name: routeNames.remove,
            meta: {title: 'Remove Queue'},
            props: true,
            component: RemoveQueues
        },
        {
            path: ':queueUuid/users',
            name: routeNames.agents.list,
            meta: {title: 'Queue Users'},
            props: true,
            component: QueueAgents,
            children: [
                {
                    path: 'add',
                    name: routeNames.agents.add,
                    meta: {title: 'Add Queue Users'},
                    props: true,
                    component: AddQueueAgents
                },
                {
                    path: 'create',
                    name: routeNames.agents.create,
                    meta: {title: 'Create Queue User'},
                    props: (route) => ({
                        ...route.params,
                        redirect: {
                            name: routeNames.agents.list,
                            params: route.params
                        }
                    }),
                    component: ManageAgent
                },
                {
                    path: 'remove/multiple',
                    name: routeNames.agents.bulkRemove,
                    meta: {title: 'Remove Users'},
                    props: (route) => ({
                        ...route.params,
                        bulk: true
                    }),
                    component: RemoveQueueAgents
                },
                {
                    path: 'remove/:agentUuid',
                    name: routeNames.agents.remove,
                    meta: {title: 'Remove User'},
                    props: true,
                    component: RemoveQueueAgents
                }
            ]
        }
    ]
}
