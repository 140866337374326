import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'

const initialState = () => ({
    status: {
        created_at: null,
        name: null,
        status_type: null,
        timeout: undefined,
        allowance: undefined,
        allowance_reminder: false,
        updated_at: null,
        uuid: null
    }
})

const api = apiModule.cloudTelephony.contactCentre.centre.statuses.status

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        status: state => state.status
    },
    actions: {
        createStatus: async ({dispatch}, data) => {
            try {
                const response = await api.createStatus(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        showStatus: async ({commit, dispatch}, data) => {
            try {
                const {entity} = await api.showStatus(data)
                commit(mutationTypes.SET_STATUS, entity)
                return entity
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        updateStatus: async ({dispatch}, data) => {
            try {
                const response = await api.updateStatus(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeStatuses: async ({dispatch}, data) => {
            try {
                const response = await api.removeStatuses(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetStatus: ({commit}) => commit(mutationTypes.RESET_STATUS)
    },
    mutations: {
        [mutationTypes.SET_STATUS]: (state, data) => state.status = data,
        [mutationTypes.RESET_STATUS]: state => Object.assign(state, initialState())
    }
}
