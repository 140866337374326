import * as mutationTypes from './mutation-types'
import apiModule from '@/store/api'

// Modules
import card from './modules/card'

const api = apiModule
    .cloudTelephony
    .contactCentre
    .centre
    .wallboards
    .wallboard
    .cards

const initialState = () => ({
    wallboardCards: {
        data: [],
        pagination: {}
    },
    wallboardCardTypes: {
        data: [],
        pagination: {}
    }
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        wallboardCards: state => state.wallboardCards,
        wallboardCardTypes: state => state.wallboardCardTypes
    },
    actions: {
        listWallboardCards: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listWallboardCards(data)
                commit(mutationTypes.SET_WALLBOARD_CARDS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        listWallboardCardTypes: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listWallboardCardTypes(data)
                commit(mutationTypes.SET_WALLBOARD_CARD_TYPES, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetWallboardCards: ({commit}) => commit(mutationTypes.RESET_WALLBOARD_CARDS),
        resetWallboardCardTypes: ({commit}) => commit(mutationTypes.RESET_WALLBOARD_CARD_TYPES)
    },
    modules: {
        card
    },
    mutations: {
        [mutationTypes.SET_WALLBOARD_CARDS]: (state, data) => state.wallboardCards = data,
        [mutationTypes.SET_WALLBOARD_CARD_TYPES]: (state, data) => state.wallboardCardTypes = data,
        [mutationTypes.RESET_WALLBOARD_CARDS]: state => Object.assign(state, initialState()),
        [mutationTypes.RESET_WALLBOARD_CARD_TYPES]: state => state.wallboardCardTypes = initialState().wallboardCardTypes
    }
}
