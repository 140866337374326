// modules
import centre from './modules/centre'
import extensions from './modules/extensions'

export default {
    namespaced: true,
    modules: {
        centre,
        extensions
    }
}
