import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'
import {utcToLocalTime} from '@core/utils/date'

const initialState = () => ({
    autoSignOut: {}
})

const api = apiModule.cloudTelephony.contactCentre.centre.autoSignOuts.autoSignOut

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        autoSignOut: state => state.autoSignOut
    },
    actions: {
        createAutoSignOut: async ({dispatch}, data) => {
            try {
                const response = await api.createAutoSignOut(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        updateAutoSignOut: async ({dispatch}, data) => {
            try {
                const response = await api.updateAutoSignOut(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeAutoSignOuts: async ({dispatch}, uuids) => {
            try {
                const response = await api.removeAutoSignOuts(uuids)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        showAutoSignOut: async ({commit, dispatch}, uuid) => {
            try {
                const response = await api.showAutoSignOut(uuid)
                commit(mutationTypes.SET_AUTO_SIGN_OUT, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetAutoSignOut: ({commit}) => commit(mutationTypes.RESET_AUTO_SIGN_OUT)
    },
    mutations: {
        [mutationTypes.SET_AUTO_SIGN_OUT]: (state, data) => {
            state.autoSignOut = {
                ...data,
                time: utcToLocalTime(data.time)
            }
        },
        [mutationTypes.RESET_AUTO_SIGN_OUT]: state => Object.assign(state, initialState())
    }
}
