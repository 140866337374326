import routeNames from './route-names'
import {DISABLED, ENABLED} from '@/store/static/cloud-telephony/agent-user-statuses'

const Agents = () => import(/* webpackChunkName: "contact-centre-agents" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/Agents')
const ManageAgent = () => import(/* webpackChunkName: "contact-centre-agents" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/modules/agent/ManageAgent')
const ManageAgentPresence = () => import(/* webpackChunkName: "contact-centre-agents" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/modules/agent/ManageAgentPresence')
const ManageAgentStatus = () => import(/* webpackChunkName: "contact-centre-agents" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/modules/agent/ManageAgentStatus')
const RemoveAgents = () => import(/* webpackChunkName: "contact-centre-agents" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/modules/agent/RemoveAgents')

// Queues
const AgentQueues = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/modules/agent/modules/queues/AgentQueues')
const AddAgentQueues = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/modules/agent/modules/queues/AddAgentQueues')
const ManageQueue = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/ManageQueue')
const ManageQueueSettings = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/ManageQueueSettings')
const RemoveAgentQueues = () => import(/* webpackChunkName: "contact-centre-queues" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/agents/modules/agent/modules/queues/RemoveAgentQueues')

export default {
    path: 'users',
    name: routeNames.list,
    meta: {title: 'Users'},
    component: Agents,
    children: [
        {
            path: 'create',
            name: routeNames.create,
            meta: {title: 'Enable User'},
            props: (route) => ({
                ...route.params,
                redirect: {
                    name: routeNames.list
                }
            }),
            component: ManageAgent
        },
        {
            path: 'disable/multiple',
            name: routeNames.bulkDisable,
            meta: {title: 'Disable Users'},
            props: (route) => ({
                ...route.params,
                bulk: true,
                mode: DISABLED
            }),
            component: ManageAgentStatus
        },
        {
            path: 'disable/:agentUuid',
            name: routeNames.disable,
            meta: {title: 'Disable User'},
            props: (route) => ({
                ...route.params,
                mode: DISABLED
            }),
            component: ManageAgentStatus
        },
        {
            path: 'enable/multiple',
            name: routeNames.bulkEnable,
            meta: {title: 'Enable Users'},
            props: (route) => ({
                ...route.params,
                bulk: true,
                mode: ENABLED
            }),
            component: ManageAgentStatus
        },
        {
            path: 'enable/:agentUuid',
            name: routeNames.enable,
            meta: {title: 'Enable User'},
            props: (route) => ({
                ...route.params,
                mode: ENABLED
            }),
            component: ManageAgentStatus
        },
        {
            path: 'update/:agentUuid',
            name: routeNames.update,
            meta: {title: 'Update User'},
            props: (route) => ({
                ...route.params,
                redirect: {
                    name: routeNames.list
                }
            }),
            component: ManageAgent
        },
        {
            path: 'update/:agentUuid/presence',
            name: routeNames.updatePresence,
            meta: {title: 'Update Presence'},
            props: (route) => ({
                ...route.params,
                redirect: {
                    name: routeNames.list
                }
            }),
            component: ManageAgentPresence
        },
        {
            path: 'remove/multiple',
            name: routeNames.bulkRemove,
            meta: {title: 'Remove Users'},
            props: (route) => ({
                ...route.params,
                bulk: true
            }),
            component: RemoveAgents
        },
        {
            path: 'remove/:agentUuid',
            name: routeNames.remove,
            meta: {title: 'Remove User'},
            props: true,
            component: RemoveAgents
        },
        {
            path: ':agentUuid/queues',
            name: routeNames.queues.list,
            meta: {title: 'User Queues'},
            props: true,
            component: AgentQueues,
            children: [
                {
                    path: 'add',
                    name: routeNames.queues.add,
                    meta: {title: 'Add User to Queues'},
                    props: true,
                    component: AddAgentQueues
                },
                {
                    path: 'create',
                    name: routeNames.queues.create,
                    meta: {title: 'Create User Queue'},
                    props: (route) => ({
                        ...route.params,
                        redirectOnClose: {
                            name: routeNames.queues.list,
                            params: route.params
                        },
                        redirectOnCreate: {
                            name: routeNames.queues.settings,
                            params: route.params
                        }
                    }),
                    component: ManageQueue
                },
                {
                    path: ':queueUuid/settings',
                    name: routeNames.queues.settings,
                    meta: {title: 'Queue Settings'},
                    props: (route) => ({
                        ...route.params,
                        redirect: {
                            name: routeNames.queues.list,
                            params: route.params
                        }
                    }),
                    component: ManageQueueSettings
                },
                {
                    path: 'remove/multiple',
                    name: routeNames.queues.bulkRemove,
                    meta: {title: 'Remove Users from Queues'},
                    props: (route) => ({
                        ...route.params,
                        bulk: true
                    }),
                    component: RemoveAgentQueues
                },
                {
                    path: 'remove/:queueUuid',
                    name: routeNames.queues.remove,
                    meta: {title: 'Remove User from Queues'},
                    props: true,
                    component: RemoveAgentQueues
                }
            ]
        }
    ]
}
