import routeNames from './route-names'

import autoSignOutsModule from './modules/auto-signouts'
import scheduledReportsModule from './modules/scheduled-reports'
import statusesModule from './modules/statuses'
import enforce2FAModule from './modules/enforce-2fa'

const Settings = () => import(/* webpackChunkName: "contact-centre-settings" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/Settings')
const GeneralSettings = () => import(/* webpackChunkName: "contact-centre-settings" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/GeneralSettings')

export default {
    path: 'settings',
    component: Settings,
    props: true,
    children: [
        {
            path: '/',
            name: routeNames.show,
            meta: {title: 'Settings'},
            component: GeneralSettings,
            props: true
        },
        autoSignOutsModule,
        statusesModule,
        scheduledReportsModule,
        enforce2FAModule
    ]
}
