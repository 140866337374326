import {findWhere, isEmpty} from 'underscore'
import * as mutationTypes from './mutation-types'
import api from '@/store/api'

const namespaced = true

const initialState = () => ({
    auth: {
        customer_id: 0,
        account_code: '',
        accounts: [],
        company_name: '',
        email_address: '',
        token: '',
        has_mfa: false,
        has_force_mfa: false,
        force_mfa_data: null,
        impersonated: false
    },
    login: false,
    mfaError: {},
    loading: false,
    nextRoute: {},
    fromRoute: {}
})

const state = initialState()

const getters = {
    auth: state => state.auth,
    accountCode: state => state.auth.account_code,
    customerId: state => state.auth.customer_id,
    companyName: state => state.auth.company_name,
    accounts: state => state.auth?.accounts,
    impersonated: state => state.auth.impersonated,
    accountUrl: (state, getters) => (path = '') => `${process.env.VUE_APP_MY_ACCOUNT_URL}${path}?access_token=${getters.token}`,
    accountRedirectUrl: (state, getters) => getters.accountUrl('/login'),
    mfaRedirectUrl: (state, getters) => getters.accountUrl('/security/two-factor-auth'),
    myWindsorRedirectUrl: (state, getters) => `${process.env.VUE_APP_MY_WINDSOR_URL}?token=${getters.token}&customer_id=${getters.customerId}`,
    emailAddress: state => state.auth.email_address,
    hasMfaError: state => !isEmpty(state.mfaError),
    mfaError: state => state.mfaError,
    nextRoute: state => state.nextRoute,
    fromRoute: state => state.fromRoute,
    isAuthed: state => Boolean(state.auth?.token),
    token: state => state.auth.token
}

const actions = {
    login: async ({commit, dispatch}, data) => {
        try {
            const response = await api.auth.login(data)
            commit(mutationTypes.SET_LOGIN, true)
            commit(mutationTypes.SET_AUTH, response)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    me: async ({commit, dispatch}, data) => {
        try {
            const response = await api.auth.me(data)
            commit(mutationTypes.SET_AUTH, response)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    logout: async ({commit, dispatch}) => {
        try {
            const response = await api.auth.logout()
            commit(mutationTypes.RESET_AUTH)
            return response
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    ping: async ({dispatch}) => {
        try {
            return await api.auth.ping()
        } catch (e) {
            const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
            return Promise.reject(errorResponse[0])
        }
    },
    resetAuth: async ({commit}) => commit(mutationTypes.RESET_AUTH),
    resetMfaError: async ({commit}) => commit(mutationTypes.RESET_MFA_ERROR),
    setAuth: async ({commit}, data) => commit(mutationTypes.SET_AUTH, data),
    setLoading: async ({commit}, data) => commit(mutationTypes.SET_LOADING, data),
    setMfaError: async ({commit}, data) => commit(mutationTypes.SET_MFA_ERROR, data),
    setNextRoute: async ({commit}, data) => commit(mutationTypes.SET_NEXT_ROUTE, data),
    setFromRoute: async ({commit}, data) => commit(mutationTypes.SET_FROM_ROUTE, data)
}

const mutations = {
    [mutationTypes.RESET_AUTH] (state) {
        Object.assign(state, initialState())
    },
    [mutationTypes.RESET_MFA_ERROR] (state) {
        state.mfaError = initialState().mfaError
    },
    [mutationTypes.SET_AUTH] (state, data) {
        const {accounts, has_mfa, customer_id} = data
        const account = findWhere(accounts, {customer_id})
        const requiresMfa = account.has_force_mfa

        if (requiresMfa && !has_mfa) {
            const mfaData = account.force_mfa_data
            const blockAccess = mfaData.block_access

            if (blockAccess || state.login) {
                state.mfaError = {
                    status: 401,
                    message: account.force_mfa_data.message
                }
            }
        }

        state.auth = data
    },
    [mutationTypes.SET_LOGIN] (state, data) {
        state.login = data
    },
    [mutationTypes.SET_LOADING] (state, data) {
        state.loading = data
    },
    [mutationTypes.SET_MFA_ERROR] (state, data) {
        state.mfaError = data
    },
    [mutationTypes.SET_NEXT_ROUTE] (state, data) {
        state.nextRoute = data
    },
    [mutationTypes.SET_FROM_ROUTE] (state, data) {
        state.fromRoute = data
    }
}

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
