export const SET_WALLBOARD_CARDS = 'SET_WALLBOARD_CARDS'
export const SET_WALLBOARD_CARD_TYPES = 'SET_WALLBOARD_CARD_TYPES'
export const RESET_WALLBOARD_CARDS = 'RESET_WALLBOARD_CARDS'
export const RESET_WALLBOARD_CARD_TYPES = 'RESET_WALLBOARD_CARD_TYPES'

export default {
    SET_WALLBOARD_CARDS,
    SET_WALLBOARD_CARD_TYPES,
    RESET_WALLBOARD_CARDS,
    RESET_WALLBOARD_CARD_TYPES
}
