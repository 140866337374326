import {omit} from 'underscore'
import client from '@/http'

/**
 * Centre Queue API endpoints
 */
export default {
    // Centre Queue API
    async createQueue (data) {
        try {
            const response = await client.post('/contact-centre/queues', data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async removeQueues (uuids) {
        try {
            const response = await client.delete(`/contact-centre/queues`, {data: {uuids: uuids}})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async showQueue (uuid) {
        try {
            const response = await client.get(`/contact-centre/queues/${uuid}`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async updateQueue (data) {
        try {
            const response = await client.put(`/contact-centre/queues/${data.uuid}`, data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async updateQueueSettings (data) {
        try {
            const response = await client.put(`/contact-centre/queues/${data.uuid}/settings`, {
                ...omit(data, ['name', 'uuid'])
            })
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async addAgentsToQueue (data) {
        try {
            const response = await client.post(
                `/contact-centre/queues/${data.uuid}/agents`,
                {uuids: data.agent_uuids}
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async removeAgentsFromQueue (data) {
        try {
            const response = await client.delete(
                `/contact-centre/queues/${data.uuid}/agents`,
                {data: {uuids: data.agent_uuids}}
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
