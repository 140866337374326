import * as mutationTypes from './mutation-types'
import {contains, filter, map, pluck} from 'underscore'
import dateUtils from '@core/utils/date'
import statuses from '@framework/store/static/agent-statuses'
// API
import apiModule from '@/store/api'

const initialState = () => ({
    agent: {},
    status: {},
    statusTime: null,
    statusToAdd: {},
    statusToRemove: {},
    signIn: false
})

const api = apiModule.cloudTelephony.contactCentre.centre.agents.agent

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        agent: state => state.agent,
        agentExtension: state => state.agent && state.agent.extension && state.agent.extension.short_number,
        agentStatus: (state, getters) => {
            const statusMapped = getters.mapAgentStatus(state.status?.status)
            const msElapsed = statusMapped.allowanceUsed || (new Date() - new Date(statusMapped.startTime))
            const allowance = statusMapped.allowance || 0

            return {
                ...statusMapped,
                startTime: state.statusTime,
                allowanceFormatted: allowance
                    ? dateUtils.formatDuration(allowance / 1000)
                    : null,
                allowanceReached: Boolean(allowance && (msElapsed >= allowance))
            }
        },
        agentAvailableStatuses: (state, getters, rootState, rootGetters) => {
            const status = state.status
            const queuedStatusUuids = status && pluck(status.queuedStatuses, 'uuid')
            let statusList = rootGetters['cloudTelephony/contactCentre/centre/statusList']

            statusList = filter(statusList, status => {
                return !status.isSystemStatus
                    && status.statusType === statuses.STATUS_CUSTOM
                    && !contains(queuedStatusUuids, status.uuid)
            })

            return map(statusList, getters.mapAgentStatus)
        },
        agentQueuedStatuses: (state, getters) => {
            let statuses = (state.status && state.status.queuedStatuses) || []

            statuses = filter(statuses, status => {
                return status.uuid !== getters.agentStatus.uuid
            })

            return map(statuses, getters.mapAgentStatus)
        },
        agentUuid: state => state.agent && state.agent.uuid,
        mapAgentStatus: (state, getters, rootState, rootGetters) => {
            return rootGetters['cloudTelephony/contactCentre/centre/mapAgentStatus']
        }
    },
    actions: {
        createAgent: async ({dispatch}, data) => {
            try {
                const response = await api.createAgent(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        disableAgents: async ({dispatch}, data) => {
            try {
                return await api.disableAgents(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        enableAgents: async ({dispatch}, data) => {
            try {
                return await api.enableAgents(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeAgents: async ({dispatch}, uuids) => {
            try {
                const response = await api.removeAgents(uuids)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        showAgent: async ({commit, dispatch}, uuid) => {
            try {
                const response = await api.showAgent(uuid)
                commit(mutationTypes.SET_AGENT, response.agent)
                return response.agent
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        addQueuesToAgent: async ({dispatch}, data) => {
            try {
                const response = await api.addQueuesToAgent(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeQueuesFromAgent: async ({dispatch}, data) => {
            try {
                const response = await api.removeQueuesFromAgent(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        addAgentStatus: ({commit}, data) => commit(mutationTypes.ADD_AGENT_STATUS, data),
        removeAgentStatus: ({commit}, data) => commit(mutationTypes.REMOVE_AGENT_STATUS, data),
        signInAgent: ({commit}, data) => commit(mutationTypes.SET_AGENT_SIGN_IN, data),
        signOutAgent: ({commit}, data) => commit(mutationTypes.SET_AGENT_SIGN_OUT, data),
        resetAgent: ({commit}) => commit(mutationTypes.RESET_AGENT)
    },
    mutations: {
        [mutationTypes.SET_AGENT]: (state, data) => state.agent = data,
        [mutationTypes.SET_AGENT_SIGN_IN]: (state, data) => state.signIn = data,
        [mutationTypes.SET_AGENT_SIGN_OUT]: (state, data) => state.signIn = data,
        [mutationTypes.SET_AGENT_STATUS]: (state, data) => state.status = data,
        [mutationTypes.RESET_AGENT]: state => Object.assign(state, initialState()),
        [mutationTypes.ADD_AGENT_STATUS]: (state, data) => state.statusToAdd = data,
        [mutationTypes.REMOVE_AGENT_STATUS]: (state, data) => state.statusToRemove = data,
        [mutationTypes.UPDATE_AGENT_STATUS_TIME]: (state) => {
            const status = state.status && state.status.status

            if (status && status.startTime) {
                const allowanceUsed = status.allowanceUsed || 0
                state.statusTime = dateUtils.fromNowDuration(new Date(new Date(status.startTime) - allowanceUsed))
            }
        }
    }
}
