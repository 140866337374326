import * as mutationTypes from './mutation-types'

// API
import apiModule from '@/store/api'
import {map} from 'underscore'

const initialState = () => ({
    reports: {
        data: [],
        pagination: {}
    },
    report: {
        name: undefined,
        recipients: [],
        filters: {
            report: undefined,
        },
        runAt: {
            frequency: undefined,
            time: undefined,
            day: undefined,
            date: undefined
        }
    }
})

const api = apiModule.cloudTelephony.contactCentre.centre.scheduledReports

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        scheduledReports: state => ({
            data: map(state.reports.data, report => {
                const {time, frequency} = report.run_at
                const localTime = time

                return {
                    ...report,
                    run_at: `${frequency} at ${localTime}`
                }
            }),
            pagination: state.reports.pagination
        }),
        scheduledReport: state => ({
            ...state.report,
            runAt: {
                ...state.report.runAt,
                time: state.report.runAt.time
            }
        })
    },
    actions: {
        listScheduledReports: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listReports(data)
                commit(mutationTypes.SET_REPORTS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        showScheduledReport: async ({commit, dispatch}, {uuid, data}) => {
            try {
                const response = await api.showReport(uuid, data)
                commit(mutationTypes.SET_REPORT, response.report)
                return response.report
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        createScheduledReport: async ({dispatch}, data) => {
            try {
                return await api.createReport(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        updateScheduledReport: async ({dispatch}, {uuid, data}) => {
            try {
                const response = await api.updateReport(uuid, data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeScheduledReports: async ({dispatch}, data) => {
            try {
                const response = await api.removeReports(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetScheduledReports: async ({commit}) => commit(mutationTypes.RESET_REPORTS),
        resetScheduledReport: async ({commit}) => commit(mutationTypes.RESET_REPORT)
    },
    mutations: {
        [mutationTypes.SET_REPORTS]: (state, data) => state.reports = data,
        [mutationTypes.SET_REPORT]: (state, data) => state.report = data,
        [mutationTypes.RESET_REPORTS]: (state) => state.reports = initialState().reports,
        [mutationTypes.RESET_REPORT]: (state) => state.report = initialState().report
    }
}
