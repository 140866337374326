import routeNames from './route-names'

const Reporting = () => import(/* webpackChunkName: "contact-centre-reporting" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/reporting/Reporting')
const Reports = () => import(/* webpackChunkName: "contact-centre-reporting" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/reporting/modules/Reports')
const Report = () => import(/* webpackChunkName: "contact-centre-reporting" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/reporting/modules/Report')

export default {
    path: 'reporting',
    component: Reporting,
    children: [
        {
            path: '/',
            name: routeNames.list,
            meta: {title: 'Reporting'},
            props: true,
            component: Reports
        },
        {
            path: ':reportId',
            name: routeNames.show,
            meta: {title: 'Report'},
            props: true,
            component: Report
        }
    ]
}
