export const AUTO_SIGN_OUT = 'AUTO_SIGN_OUT'
export const AUTO_SIGN_OUT_CREATE = 'AUTO_SIGN_OUT_CREATE'
export const AUTO_SIGN_OUT_UPDATE = 'AUTO_SIGN_OUT_UPDATE'
export const AUTO_SIGN_OUT_REMOVE = 'AUTO_SIGN_OUT_REMOVE'
export const AUTO_SIGN_OUT_BULK_REMOVE = 'AUTO_SIGN_OUT_BULK_REMOVE'

export default {
    list: AUTO_SIGN_OUT,
    create: AUTO_SIGN_OUT_CREATE,
    update: AUTO_SIGN_OUT_UPDATE,
    remove: AUTO_SIGN_OUT_REMOVE,
    bulkRemove: AUTO_SIGN_OUT_BULK_REMOVE
}
