import client from '@/http'

const PATH = 'contact-centre/reports'

/**
 * Centre Reporting API endpoints
 */
export default {
    /**
     * @param {{filters?: {}, page?: number, per_page?: number}} data
     */
    async showReport (type, data) {
        try {
            const response = await client.get(`${PATH}/${type}`, {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{email_address: string, filters?: {}, page?: number, per_page?: number}} data
     */
    async exportReport (type, data) {
        try {
            const response = await client.get(`${PATH}/${type}/csv`, {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{search?: string}} data
     */
    async searchAgents (data) {
        try {
            const response = await client.get(`${PATH}/search/agents`, {params: data})

            let agents = response.data
            if (response.data.pagination.page_count > 1) {
                for (let indexPage = 2; indexPage < response.data.pagination.page_count + 1; indexPage++) {
                    const responsePage = await client.get(`${PATH}/search/agents`, {
                        params: {...data, page: indexPage}
                    })

                    agents = {
                        pagination: responsePage.pagination,
                        data: [ ...agents.data, ...responsePage.data.data]
                    }
                }
            }

            return agents
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{search?: string}} data
     */
    async searchQueues (data) {
        try {
            const response = await client.get(`${PATH}/search/queues`, {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{search?: string}} data
     */
    async searchStatuses (data) {
        try {
            const response = await client.get(`${PATH}/search/statuses`, {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
