import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import {contains, isEmpty} from 'underscore'
import routeNames from './route-names'
// Modules
import cloudTelephony from './modules/cloud-telephony'
import {CLOUD_TELEPHONY} from '@/store/static/namespaces'
import qs from 'qs'
import WallboardCardForm from '@/components/cloud-telephony/contact-centre/WallboardCardForm'

const Login = () => import(/* webpackChunkName: "customer-portal-app-wrapper" */'../views/modules/auth/Login')
const Logout = () => import(/* webpackChunkName: "customer-portal-app-wrapper" */'../views/modules/auth/Logout')
const BaseNotFound = () => import(/* webpackChunkName: "customer-portal-app-wrapper" */'@framework/components/BaseNotFound')

Vue.use(VueRouter)

export const routes = [
    {
        path: '/',
        redirect: `/${CLOUD_TELEPHONY}`
    },
    {
        path: '/test',
        component: WallboardCardForm
    },
    // Modules
    cloudTelephony,
    // Auth
    {
        path: '/login',
        redirect: '/auth-check'
    },
    {
        path: '/auth-check',
        name: routeNames.login,
        component: Login
    },
    {
        path: '/logout',
        name: routeNames.logout,
        component: Logout
    },
    {
        path: '*',
        component: BaseNotFound,
        props: {
            redirect: {path: '/'}
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    parseQuery (query) {
        return qs.parse(query)
    },
    stringifyQuery (query) {
        const result = qs.stringify(query, {arrayFormat: 'brackets'})
        return result ? `?${result}` : ''
    }
})

router.beforeEach(async (to, from, next) => {
    const nextRoute = store.getters['auth/nextRoute']

    if ((to.path !== nextRoute.path) && !contains(['/login', '/auth-check'], to.path)) {
        store.dispatch('auth/resetMfaError')
        store.dispatch('auth/setNextRoute', to)
        store.dispatch('auth/setFromRoute', from)
    }

    if (to.name === from.name && isEmpty(to.query) && !isEmpty(from.query)) {
        return next({name: to.name, query: from.query})
    }

    if (to.fullPath === from.fullPath && from.path !== '/') {
        return next(false)
    }

    next()
})

export default router
