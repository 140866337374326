import client from '@/http'
// Modules
import autoSignOut from './modules/auto-sign-out'

/**
 * Auto Sign Out API endpoints
 */
export default {
    // Modules
    autoSignOut,
    // Auto Logouts API
    /**
     * @param {{page:? number, per_page?: number}} params
     * @returns {Promise<any>}
     */
    async listAutoSignOuts (params) {
        try {
            const response = await client.get('/contact-centre/auto-logouts', {params})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
