import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'

const initialState = () => ({
    filters: {
        statuses: {data: [], pagination: {}},
        agents: {data: [], pagination: {}},
        queues: {data: [], pagination: {}},
        sources: {
            data: [
                {text: 'Manager - A status that has been manually updated for a user through the Call Manager Plus portal', value: 'manager'},
                {text: 'User - Updated by the the user themselves', value: 'agent'},
                {text: 'Telephony - A status that has been triggered by a call', value: 'telephony'}
            ]
        }
    },
    report: {columnHeaders: [], rows: [], pagination: {}}
})

const api = apiModule.cloudTelephony.contactCentre.centre.reporting

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        report: state => state.report,
        filters: state => state.filters,
        statusesFilter: state => state.filters.statuses,
        agentsFilter: state => state.filters.agents,
        sourcesFilter: state => state.filters.sources,
        queuesFilter: state => state.filters.queues
    },
    actions: {
        showReport: async ({commit, dispatch}, {type, data}) => {
            try {
                const response = await api.showReport(type, data)
                commit(mutationTypes.SET_REPORT, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        exportReport: async ({dispatch}, {type, data}) => {
            try {
                const response = await api.exportReport(type, data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        searchAgents: async ({commit, dispatch}, data) => {
            try {
                const response = await api.searchAgents(data)
                commit(mutationTypes.SET_AGENTS_FILTER, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        searchQueues: async ({commit, dispatch}, data) => {
            try {
                const response = await api.searchQueues(data)
                commit(mutationTypes.SET_QUEUES_FILTER, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        searchStatuses: async ({commit, dispatch}, data) => {
            try {
                const response = await api.searchStatuses(data)
                commit(mutationTypes.SET_STATUSES_FILTER, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetAgentsFilter: ({commit}) => commit(mutationTypes.RESET_AGENTS_FILTER),
        resetStatusesFilter: ({commit}) => commit(mutationTypes.RESET_STATUSES_FILTER),
        resetQueuesFilter: ({commit}) => commit(mutationTypes.RESET_QUEUES_FILTER),
        resetReport: ({commit}) => commit(mutationTypes.RESET_REPORT)
    },
    mutations: {
        [mutationTypes.SET_REPORT]: (state, data) => state.report = data,
        [mutationTypes.SET_AGENTS_FILTER]: (state, data) => state.filters.agents = data,
        [mutationTypes.SET_STATUSES_FILTER]: (state, data) => state.filters.statuses = data,
        [mutationTypes.SET_QUEUES_FILTER]: (state, data) => state.filters.queues = data,
        [mutationTypes.RESET_AGENTS_FILTER]: state => state.filters.agents = initialState().filters.agents,
        [mutationTypes.RESET_STATUSES_FILTER]: state => state.filters.statuses = initialState().filters.statuses,
        [mutationTypes.RESET_QUEUES_FILTER]: state => state.filters.queues = initialState().filters.queues,
        [mutationTypes.RESET_REPORT]: state => Object.assign(state, initialState())
    }
}
