import routeNames from './route-names'

const Statuses = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/statuses/Statuses')
const ManageStatus = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/statuses/modules/ManageStatus')
const RemoveStatuses = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/statuses/modules/RemoveStatuses')

export default {
    path: 'statuses',
    name: routeNames.list,
    meta: {title: 'Statuses'},
    component: Statuses,
    props: true,
    children: [
        {
            path: 'create',
            name: routeNames.create,
            meta: {title: 'Create Status'},
            props: (route) => ({
                ...route.params,
                redirect: {
                    name: routeNames.list
                }
            }),
            component: ManageStatus
        },
        {
            path: 'update/:statusUuid',
            name: routeNames.update,
            meta: {title: 'Update Status'},
            props: (route) => ({
                ...route.params,
                redirect: {
                    name: routeNames.list
                }
            }),
            component: ManageStatus
        },
        {
            path: 'remove/multiple',
            name: routeNames.bulkRemove,
            meta: {title: 'Remove Statuses'},
            props: (route) => ({
                ...route.params,
                bulk: true
            }),
            component: RemoveStatuses
        },
        {
            path: 'remove/:statusUuid',
            name: routeNames.remove,
            meta: {title: 'Remove Status'},
            props: true,
            component: RemoveStatuses
        }
    ]
}
