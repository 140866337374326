import client from '@/http'
import status from './modules/status'

/**
 * Statuses API endpoints
 */
export default {
    // Modules
    status,
    // Statuses API
    async listStatuses (params) {
        try {
            const response = await client.get('/contact-centre/statuses', {params})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
