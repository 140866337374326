import agents from './modules/agents/route-names'
import queues from './modules/queues/route-names'
import wallboards from './modules/wallboards/route-names'
import reporting from './modules/reporting/route-names'
import settings from './modules/settings/route-names'

export const HOME = 'HOME'

export default {
    home: HOME,
    agents,
    queues,
    wallboards,
    reporting,
    settings
}
