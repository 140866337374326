import client from '@/http'

const PATH = 'contact-centre/scheduled-reports'

/**
 * Centre Scheduled Reporting API endpoints
 */
export default {
    /**
     * @param {{filters?: {}, page?: number, per_page?: number}} data
     */
    async listReports (data) {
        try {
            const response = await client.get(PATH, {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {string} uuid
     * @param {{filters?: {}, page?: number, per_page?: number}} data
     */
    async showReport (uuid, data) {
        try {
            const response = await client.get(`${PATH}/${uuid}`, {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{
     *     name: string,
     *     recipients: string[],
     *     filters: {report: number, queues?: [], agents?: [], statuses?: [], sources?: []},
     *     runAt: {frequency: number, time: number, day: string, date: string}
     * }} data
     */
    async createReport (data) {
        try {
            const response = await client.post(PATH, data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {string} uuid
     * @param {{
     *     name: string,
     *     recipients: string[],
     *     filters: {report: number, queues?: [], agents?: [], statuses?: [], sources?: []},
     *     runAt: {frequency: number, time: number, day: string, date: string}
     * }} data
     */
    async updateReport (uuid, data) {
        try {
            const response = await client.put(`${PATH}/${uuid}`, data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuids: string[]}} data
     */
    async removeReports (data) {
        try {
            const response = await client.delete(PATH, {data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
