import client from '@/http'

/**
 * Centre Agent API endpoints
 */
export default {
    // Centre Agent API
    async createAgent (data) {
        try {
            const response = await client.post('/contact-centre/agents', data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async disableAgents (data) {
        try {
            const response = await client.post('/contact-centre/agents/disable', {uuids: data.agent_uuids})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async enableAgents (data) {
        try {
            const response = await client.post(`/contact-centre/agents/enable`, {uuids: data.agent_uuids})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async removeAgents (uuids) {
        try {
            const response = await client.delete(`/contact-centre/agents`, {data: {uuids: uuids}})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async showAgent (uuid) {
        try {
            const response = await client.get(`/contact-centre/agents/${uuid}`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async addQueuesToAgent (data) {
        try {
            const response = await client.post(
                `/contact-centre/agents/${data.uuid}/queues`,
                {uuids: data.queue_uuids}
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async removeQueuesFromAgent (data) {
        try {
            const response = await client.delete(
                `/contact-centre/agents/${data.uuid}/queues`,
                {data: {uuids: data.queue_uuids}}
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
