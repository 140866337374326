export const SCHEDULED_REPORTS = 'SCHEDULED_REPORTS'
export const SCHEDULED_REPORTS_CREATE = 'SCHEDULED_REPORTS_CREATE'
export const SCHEDULED_REPORTS_UPDATE = 'SCHEDULED_REPORTS_UPDATE'
export const SCHEDULED_REPORTS_REMOVE = 'SCHEDULED_REPORTS_REMOVE'
export const SCHEDULED_REPORTS_BULK_REMOVE = 'SCHEDULED_REPORTS_BULK_REMOVE'

export default {
    list: SCHEDULED_REPORTS,
    create: SCHEDULED_REPORTS_CREATE,
    update: SCHEDULED_REPORTS_UPDATE,
    remove: SCHEDULED_REPORTS_REMOVE,
    bulkRemove: SCHEDULED_REPORTS_BULK_REMOVE
}
