import contactCentre from './modules/contact-centre'
import extensions from './modules/extensions'

export default {
    namespaced: true,
    modules: {
        contactCentre,
        extensions
    }
}
