import routeNames from './route-names'

const ScheduledReports = () => import(/* webpackChunkName: "contact-centre-scheduled-reports" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/scheduled-reports/ScheduledReports')
const ManageScheduledReport = () => import(/* webpackChunkName: "contact-centre-scheduled-reports" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/scheduled-reports/ManageScheduledReport')
const RemoveScheduledReports = () => import(/* webpackChunkName: "contact-centre-scheduled-reports" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/scheduled-reports/RemoveScheduledReports')

export default {
    path: 'scheduled-reports',
    name: routeNames.list,
    meta: {title: 'Scheduled Reports'},
    component: ScheduledReports,
    children: [
        {
            path: 'create',
            name: routeNames.create,
            meta: {title: 'Create ScheduledReport'},
            props: true,
            component: ManageScheduledReport
        },
        {
            path: 'update/:reportUuid',
            name: routeNames.update,
            meta: {title: 'Update Scheduled Report'},
            props: true,
            component: ManageScheduledReport
        },
        {
            path: 'remove/multiple',
            name: routeNames.bulkRemove,
            meta: {title: 'Remove Scheduled Reports'},
            props: (route) => ({
                ...route.params,
                bulk: true
            }),
            component: RemoveScheduledReports
        },
        {
            path: 'remove/:reportUuid',
            name: routeNames.remove,
            meta: {title: 'Remove Scheduled Report'},
            props: true,
            component: RemoveScheduledReports
        }
    ]
}
