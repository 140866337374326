import routeNames from './route-names'
// Modules
import extension from './modules/extension'

const Extensions = () => import(/* webpackChunkName: "extensions" */ '@/views/modules/cloud-telephony/modules/extensions/Extensions.vue')

export default {
    path: 'extensions',
    name: routeNames.list,
    component: Extensions,
    children: [
        extension
    ]
}
