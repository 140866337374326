import {dayOfWeek} from '@/store/static/cloud-telephony/days-of-week'
import {utcToLocalTime} from '@core/utils/date'
import autoSignOutModes from '@/store/static/cloud-telephony/auto-sign-out-modes'
import * as mutationTypes from './mutation-types'
import apiModule from '@/store/api'
// Modules
import autoSignOut from './modules/auto-sign-out'

const api = apiModule.cloudTelephony.contactCentre.centre.autoSignOuts

const initialState = () => ({
    autoSignOuts: {
        data: [],
        pagination: {}
    }
})

export default {
    namespaced: true,
    state: initialState(),
    modules: {
        autoSignOut
    },
    getters: {
        autoSignOuts: state => state.autoSignOuts
    },
    actions: {
        listAutoSignOuts: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listAutoSignOuts(data)
                commit(mutationTypes.SET_AUTO_SIGN_OUTS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetAutoSignOuts: ({commit}) => commit(mutationTypes.RESET_AUTO_SIGN_OUTS)
    },
    mutations: {
        [mutationTypes.SET_AUTO_SIGN_OUTS]: (state, {data, pagination}) => {
            state.autoSignOuts.pagination = pagination
            state.autoSignOuts.data = data.map(autoSignOut => {
                const {days, time} = autoSignOut.run_at
                const {targets} = autoSignOut
                const {global} = targets

                return {
                    agent_count: targets.agents,
                    days: days.map(value => dayOfWeek(value).text).join(', '),
                    description: autoSignOut.description,
                    global,
                    mode: global ? autoSignOutModes.ALL_USERS : autoSignOutModes.CUSTOM_USERS,
                    queue_count: targets.queues,
                    time: utcToLocalTime(time).substr(0, 5),
                    uuid: autoSignOut.uuid
                }
            })
        },
        [mutationTypes.RESET_AUTO_SIGN_OUTS]: state => Object.assign(state, initialState())
    }
}
