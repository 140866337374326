export const QUEUES = 'QUEUES'
export const QUEUES_CREATE = 'QUEUES_CREATE'
export const QUEUES_REMOVE = 'QUEUES_REMOVE'
export const QUEUES_BULK_REMOVE = 'QUEUES_BULK_REMOVE'
export const QUEUES_UPDATE = 'QUEUES_UPDATE'
export const QUEUES_SETTINGS = 'QUEUES_SETTINGS'

// Agents
export const QUEUE_AGENTS = 'QUEUE_AGENTS'
export const QUEUE_AGENTS_ADD = 'QUEUE_AGENTS_ADD'
export const QUEUE_AGENTS_CREATE = 'QUEUE_AGENTS_CREATE'
export const QUEUE_AGENTS_REMOVE = 'QUEUE_AGENTS_REMOVE'
export const QUEUE_AGENTS_BULK_REMOVE = 'QUEUE_AGENTS_BULK_REMOVE'

export default {
    list: QUEUES,
    create: QUEUES_CREATE,
    bulkRemove: QUEUES_BULK_REMOVE,
    remove: QUEUES_REMOVE,
    update: QUEUES_UPDATE,
    settings: QUEUES_SETTINGS,
    agents: {
        list: QUEUE_AGENTS,
        add: QUEUE_AGENTS_ADD,
        create: QUEUE_AGENTS_CREATE,
        remove: QUEUE_AGENTS_REMOVE,
        bulkRemove: QUEUE_AGENTS_BULK_REMOVE
    }
}
