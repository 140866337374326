import * as mutationTypes from './mutation-types'
import apiModule from '@/store/api'

// Modules
import wallboard from './modules/wallboard'

const api = apiModule.cloudTelephony.contactCentre.centre.wallboards

const initialState = () => ({
    wallboards: {
        data: [],
        pagination: {}
    }
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        wallboards: state => state.wallboards
    },
    actions: {
        listWallboards: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listWallboards(data)
                commit(mutationTypes.SET_WALLBOARDS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetWallboards: ({commit}) => commit(mutationTypes.RESET_WALLBOARDS)
    },
    modules: {
        wallboard
    },
    mutations: {
        [mutationTypes.SET_WALLBOARDS]: (state, data) => state.wallboards = data,
        [mutationTypes.RESET_WALLBOARDS]: state => Object.assign(state, initialState())
    }
}
