import client from '@/http'
import card from './modules/card'

/**
 * Centre Wallboard Cards API endpoints
 */
export default {
    // Modules
    card,
    // Centre Wallboard Cards API
    /**
     * @param {{wallboardUuid: string, params: {page:? number, per_page?: number}}} data
     */
    async listWallboardCards ({wallboardUuid, params}) {
        try {
            const response = await client
                .get(`/contact-centre/wallboards/${wallboardUuid}/cards`, {params})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{params: {page:? number, per_page?: number}}} data
     */
    async listWallboardCardTypes ({params}) {
        try {
            const response = await client.get(`/contact-centre/wallboards/cards/types`, {params})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
