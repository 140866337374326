import routeNames from './route-names'

const Wallboards = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/Wallboards')
const ManageWallboard = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/ManageWallboard')
const RemoveWallboards = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/RemoveWallboards')
const DuplicateWallboard = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/DuplicateWallboard')

// Queues
const WallboardQueues = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/modules/queues/WallboardQueues')
const AddWallboardQueues = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/modules/queues/AddWallboardQueues')
const ManageQueue = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/ManageQueue')
const ManageQueueSettings = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/queues/modules/queue/ManageQueueSettings')
const RemoveWallboardQueues = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/modules/queues/RemoveWallboardQueues')

// Cards
const WallboardCards = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/modules/cards/WallboardCards')
const ManageWallboardCard = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/modules/cards/ManageWallboardCard')
const RemoveWallboardCards = () => import(/* webpackChunkName: "contact-centre-wallboards" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/wallboards/modules/wallboard/modules/cards/RemoveWallboardCards')

export default {
    path: 'wallboards',
    name: routeNames.list,
    meta: {title: 'Wallboards'},
    component: Wallboards,
    children: [
        {
            path: 'create',
            name: routeNames.create,
            meta: {title: 'Create Wallboard'},
            props: true,
            component: ManageWallboard
        },
        {
            path: 'update/:wallboardUuid',
            name: routeNames.update,
            meta: {title: 'Update Wallboard'},
            props: true,
            component: ManageWallboard
        },
        {
            path: 'duplicate/:wallboardUuid',
            name: routeNames.duplicate,
            meta: {title: 'Duplicate Wallboard'},
            props: true,
            component: DuplicateWallboard
        },
        {
            path: 'remove/multiple',
            name: routeNames.bulkRemove,
            meta: {title: 'Remove Statuses'},
            props: (route) => ({
                ...route.params,
                bulk: true
            }),
            component: RemoveWallboards
        },
        {
            path: 'remove/:wallboardUuid',
            name: routeNames.remove,
            meta: {title: 'Remove Status'},
            props: true,
            component: RemoveWallboards
        },
        {
            path: ':wallboardUuid/queues',
            name: routeNames.queues.list,
            meta: {title: 'Wallboard Queues'},
            props: true,
            component: WallboardQueues,
            children: [
                {
                    path: 'add',
                    name: routeNames.queues.add,
                    meta: {title: 'Add Wallboard to Queues'},
                    props: true,
                    component: AddWallboardQueues
                },
                {
                    path: 'create',
                    name: routeNames.queues.create,
                    meta: {title: 'Create Queue'},
                    props: (route) => ({
                        ...route.params,
                        redirectOnClose: {
                            name: routeNames.queues.list,
                            params: route.params
                        },
                        redirectOnCreate: {
                            name: routeNames.queues.settings,
                            params: route.params
                        }
                    }),
                    component: ManageQueue
                },
                {
                    path: ':queueUuid/settings',
                    name: routeNames.queues.settings,
                    meta: {title: 'Queue Settings'},
                    props: (route) => ({
                        ...route.params,
                        redirect: {
                            name: routeNames.queues.list,
                            params: route.params
                        }
                    }),
                    component: ManageQueueSettings
                },
                {
                    path: 'remove/multiple',
                    name: routeNames.queues.bulkRemove,
                    meta: {title: 'Remove Wallboard from Queues'},
                    props: (route) => ({
                        ...route.params,
                        bulk: true
                    }),
                    component: RemoveWallboardQueues
                },
                {
                    path: 'remove/:queueUuid',
                    name: routeNames.queues.remove,
                    meta: {title: 'Remove Wallboard from Queues'},
                    props: true,
                    component: RemoveWallboardQueues
                }
            ]
        },
        {
            path: ':wallboardUuid/cards',
            name: routeNames.cards.list,
            meta: {title: 'Wallboard Cards'},
            props: true,
            component: WallboardCards,
            children: [
                {
                    path: 'create',
                    name: routeNames.cards.create,
                    meta: {title: 'Create Wallboard Card'},
                    props: (route) => ({
                        ...route.params,
                        redirect: {
                            name: routeNames.cards.list,
                            params: route.params
                        }
                    }),
                    component: ManageWallboardCard
                },
                {
                    path: ':wallboardCardUuid',
                    name: routeNames.cards.update,
                    meta: {title: 'Wallboard Card'},
                    props: (route) => ({
                        ...route.params,
                        redirect: {
                            name: routeNames.cards.list,
                            params: route.params
                        }
                    }),
                    component: ManageWallboardCard
                },
                {
                    path: ':wallboardCardUuid/remove',
                    name: routeNames.cards.remove,
                    meta: {title: 'Remove Wallboard Card'},
                    props: (route) => ({
                        ...route.params,
                        redirect: {
                            name: routeNames.cards.list,
                            params: route.params
                        }
                    }),
                    component: RemoveWallboardCards
                }
            ]
        }
    ]
}
