import {CC_WEBSOCKET} from '@/store/static/cloud-telephony/connection-types'
// Framework Modules
import confirm from '@framework/store/modules/confirm'
import connections from '@framework/store/modules/connections'
import errors from '@framework/store/modules/errors'
// Store Modules
import app from './app'
import auth from './auth'
import cloudTelephony from './cloud-telephony'
import websocket from './websocket'

/**
 *
 * Register modules
 */
export default {
    app,
    auth,
    cloudTelephony,
    websocket,
    confirm: confirm(),
    connections: connections([
        CC_WEBSOCKET
    ]),
    errors: errors()
}
