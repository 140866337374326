import client from '@/http'

/**
 * Status API endpoints
 */
export default {
    // Status API
    async showStatus (data) {
        try {
            const response = await client.get(`/contact-centre/statuses/${data.uuid}`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{name: string, timeout: number}} data
     */
    async createStatus (data) {
        try {
            const response = await client.post('/contact-centre/statuses', data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuid: string, data: {name: string, timeout: number}}} data
     */
    async updateStatus (data) {
        try {
            const response = await client.put(`/contact-centre/statuses/${data.uuid}`, data.data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuids: string[]}} data
     */
    async removeStatuses (data) {
        try {
            const response = await client.delete(`/contact-centre/statuses`, {data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
