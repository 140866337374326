import routeNames from './route-names'
// Modules
import agentsModule from './modules/agents'
import queuesModule from './modules/queues'
import wallboardsModule from './modules/wallboards'
import reportingModule from './modules/reporting'
import settingsModule from './modules/settings'
import {CONTACT_CENTRE} from '@/store/static/namespaces'

const Centre = () => import(/* webpackChunkName: "contact-centre-wrapper" */ '@/views/modules/cloud-telephony/modules/contact-centre/Centre')

export default {
    path: `/${CONTACT_CENTRE}`,
    component: Centre,
    props: true,
    name: routeNames.home,
    redirect: {name: routeNames.agents.list},
    children: [
        agentsModule,
        queuesModule,
        wallboardsModule,
        reportingModule,
        settingsModule
    ]
}
