import {CLOUD_TELEPHONY} from '@/store/static/namespaces'
// Modules
import contactCentre from './modules/contact-centre'
import extensions from './modules/extensions'
import routeNames from './route-names'

const CloudTelephony = () => import(/* webpackChunkName: "cloud-telephony" */'@/views/modules/cloud-telephony/CloudTelephony')

export default {
    path: `/${CLOUD_TELEPHONY}`,
    component: CloudTelephony,
    redirect: {name: routeNames.extensions.list},
    children: [
        extensions,
        contactCentre
    ]
}
