export const ALL_USERS = {
    value: 'ALL_USERS',
    text: 'All Users'
}

export const CUSTOM_USERS = {
    value: 'CUSTOM_USERS',
    text: 'Specific Users or Queues'
}

export default {
    ALL_USERS,
    CUSTOM_USERS
}
