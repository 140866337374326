import client from '@framework/http'

const Store = () => import('@/store')

const MFA_REQUIRED_CODE = 'E1025'

client.interceptors.response.use(
    async response => response,
    async (error) => {
        const data = error?.response?.data
        const status = data?.status

        Store().then(({default: store}) => {
            if (status === 401 && data?.ref === MFA_REQUIRED_CODE) {
                store.dispatch('auth/setMfaError', {
                    status,
                    message: data.message
                })
            }
        })

        return Promise.reject(error)
    }
)

export default client
