import client from '@/http'
// Modules
import user from './modules/user'

/**
 * Extension API endpoints
 */
export default {
    // Modules
    user,
    // Extension API
    /**
     * @param {number} id
     * @returns {Promise<*|any>}
     */
    async showExtension (id) {
        try {
            const response = await client.get(`/extensions/${id}`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
