import client from '@/http'

// Modules
import agents from './modules/agents'
import autoSignOuts from './modules/auto-sign-outs'
import queues from './modules/queues'
import reporting from './modules/reporting'
import scheduledReports from './modules/scheduled-reports'
import statuses from './modules/statuses'
import wallboards from './modules/wallboards'

/**
 * Centre API endpoints
 */
export default {
    // Modules
    agents,
    autoSignOuts,
    queues,
    reporting,
    scheduledReports,
    statuses,
    wallboards,
    // Centre API
    async showCentre () {
        try {
            const response = await client.get(`/contact-centre/centre`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async updateCentre (data) {
        try {
            const response = await client.put(`/contact-centre/centre`, data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
