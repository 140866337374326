import * as mutationTypes from './mutation-types'
import apiModule from '@/store/api'
import {filter, findIndex, findWhere, map, pluck} from 'underscore'
import {mapPagination} from '@core/config/tables'
// Modules
import agent from './modules/agent'

const api = apiModule.cloudTelephony.contactCentre.centre.agents

const initialState = () => ({
    agentsSearch: {
        data: [],
        pagination: {}
    },
    agents: {
        data: [],
        pagination: {}
    },
    statuses: []
})

export default {
    namespaced: true,
    state: initialState(),
    modules: {
        agent
    },
    getters: {
        agents: state => state.agents,
        agentsFormatted: (state, getters) => map(state.agents.data, (agent) => {
            let status = getters.agentStatusByExtension(agent.extension.short_number)

            status = status && status.status
                ? getters.mapAgentStatus(status.status)
                : {statusType: 'UNKNOWN'}

            return {
                ...agent,
                status
            }
        }),
        agentsExtensions: state => {
            const extensions = pluck(state.agents.data, 'extension')
            const shortNumbers = pluck(extensions, 'short_number')
            return filter(shortNumbers, Boolean)
        },
        agentsSearch: state => state.agentsSearch,
        agentsStatuses: state => state.statuses,
        agentStatusByExtension: (state, getters) => extension => {
            const mapper = getters.mapAgentStatus
            const status = findWhere(state.statuses, {extension})

            if (!status) {
                return {}
            }

            const activeStatus = mapper(status.status)
            let queuedStatuses = map(status.queuedStatuses, mapper)

            queuedStatuses = filter(queuedStatuses, status => {
                return status.uuid !== activeStatus.uuid
            })

            return {
                status: activeStatus,
                queuedStatuses
            }
        },
        mapAgentStatus: (state, getters, rootState, rootGetters) => {
            return rootGetters['cloudTelephony/contactCentre/centre/mapAgentStatus']
        }
    },
    actions: {
        listAgents: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listAgents(data)
                commit(mutationTypes.SET_AGENTS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        searchAgents: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listAgents(data)
                commit(mutationTypes.SET_AGENTS_SEARCH, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        searchFullAgents: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listFullAgents(data)
                commit(mutationTypes.SET_AGENTS_SEARCH, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetAgentsSearch: ({commit}) => commit(mutationTypes.RESET_AGENTS_SEARCH),
        resetAgents: ({commit}) => commit(mutationTypes.RESET_AGENTS)
    },
    mutations: {
        [mutationTypes.SET_AGENTS]: (state, data) => {
            state.agents.data = data.data
            state.agents.pagination = mapPagination(data.pagination)
        },
        [mutationTypes.SET_AGENTS_STATUSES]: (state, data) => state.statuses = data,
        [mutationTypes.SET_AGENTS_SEARCH]: (state, data) => state.agentsSearch = data,
        [mutationTypes.RESET_AGENTS_SEARCH]: state => state.agentsSearch = initialState().agentsSearch,
        [mutationTypes.RESET_AGENTS]: state => Object.assign(state, initialState()),
        [mutationTypes.UPDATE_AGENT_SIGNED_IN]: (state, data) => {
            const {uuid, value} = data
            const index = findIndex(state.agents.data, {uuid})

            if (index !== -1) {
                const agent = {...state.agents.data[index], is_signed_in: value}
                return state.agents.data.splice(index, 1, agent)
            }
        },
        [mutationTypes.UPDATE_AGENT_STATUS]: (state, data) => {
            const index = findIndex(state.statuses, {extension: data.extension})

            if (index !== -1) {
                return state.statuses.splice(index, 1, data)
            }

            state.statuses.push(data)
        }
    }
}
