import client from '@/http'

/**
 * Extension API endpoints
 */
export default {
    // Extension User API
    async assignUser (data) {
        try {
            const response = await client.post(`/extensions`, data)

            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async cancelUserInvite (data) {
        try {
            const response = await client.put(`/users/revoke-invite`, data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async disableAccess (data) {
        try {
            const response = await client.put(`/extensions/${data.extension_id}/disable`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async enableAccess (data) {
        try {
            const response = await client.put(`/extensions/${data.extension_id}/enable`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async resendUserInvite (data) {
        try {
            const response = await client.post(`/users/resend-invite`, data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async sendPasswordReset (data) {
        try {
            const response = await client.post(`/users/send-password-reset`, data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async unassignUser (data) {
        try {
            const response = await client.delete(`/extensions/${data.extension_id}`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async enforce2FA (data) {
        try {
            return await client.post('/users/enforce-mfa', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async disable2fa (data) {
        try {
            return await client.delete(`/users/mfa?email=${data.email}`)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async enableEmailFallback (data) {
        try {
            return await client.post('/users/enable-email-fallback', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async enableSMSFallback (data) {
        try {
            return await client.post('/users/enable-sms-fallback', data)
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
