export const ASSIGN_USER = 'ASSIGN_USER'
export const CANCEL_USER_INVITE = 'CANCEL_USER_INVITE'
export const DISABLE_ACCESS = 'DISABLE_ACCESS'
export const ENABLE_ACCESS = 'ENABLE_ACCESS'
export const DISABLE_2FA = 'DISABLE_2FA'
export const RESEND_USER_INVITE = 'RESEND_USER_INVITE'
export const SEND_PASSWORD_RESET = 'SEND_PASSWORD_RESET'
export const UNASSIGN_USER = 'UNASSIGN_USER'

export default {
    assignUser: ASSIGN_USER,
    cancelUserInvite: CANCEL_USER_INVITE,
    disableAccess: DISABLE_ACCESS,
    enableAccess: ENABLE_ACCESS,
    disable2fa: DISABLE_2FA,
    resendUserInvite: RESEND_USER_INVITE,
    sendPasswordReset: SEND_PASSWORD_RESET,
    unassignUser: UNASSIGN_USER
}
