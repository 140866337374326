<template>
    <!-- App.vue -->
    <v-app>
        <!-- Navigation drawer component-->
        <base-app-navigation
            v-if="isAuthed"
            :drawer-width="340"
            :items="navigation"
        ></base-app-navigation>

        <!--  Application bar component-->
        <base-app-bar
            v-if="isAuthed"
            :email-address="emailAddress"
            :title="title"
        >
            <v-spacer></v-spacer>
            <!-- Switch accounts -->
            <base-account-switcher
                v-if="hasMultipleAccounts"
                :accounts="accounts"
                :value="customerId"
                @change:account="handleChangeAccount"
            ></base-account-switcher>

            <!-- Account Menu -->
            <account-menu
                :account-code="accountCode"
                :account-redirect-url="accountRedirectUrl"
                :company-name="companyName"
                :email-address="emailAddress"
                :help-url="windsorUrls.CONTACT_US_URL"
                :impersonated="impersonated"
                :status-page-url="windsorUrls.STATUS_PAGE_URL"
                @click:logout="handleLogout"
            ></account-menu>
        </base-app-bar>

        <!-- Confirm Dialog -->
        <base-confirm-dialog
            v-bind="confirmProps"
            @close="closeConfirm"
            @submit="submitConfirm"
        ></base-confirm-dialog>

        <!-- Content container -->
        <v-main>
            <!-- Account Switch Overlay -->
            <v-overlay :value="loading">
                <base-app-loading
                    loading-text="Switching Account..."
                    progress-color="primary"
                    text-color="white"
                ></base-app-loading>
            </v-overlay>

            <v-container :class="{'fill-height': !isAuthed}" fluid>
                <base-force-mfa-error
                    v-if="hasMfaError"
                    :error="mfaError"
                    :redirect-url="mfaRedirectUrl"
                ></base-force-mfa-error>
                <router-view v-show="!hasMfaError"></router-view>
            </v-container>
        </v-main>

        <!-- Application footer -->
        <base-app-footer v-if="isAuthed" id="footer" :title="footer" :version="version"></base-app-footer>
    </v-app>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {LOGOUT} from './router/route-names'
    import {contains} from 'underscore'
    import windsorUrls from '@framework/store/static/windsor-urls'
    // Utils
    import logger from '@core/utils/logger'
    // Mixins
    import BaseValidationErrorsMixin from '@framework/mixins/base-validation-errors.mixin'
    // Components
    import AccountMenu from '@/components/core/AccountMenu'
    import BaseAccountSwitcher from '@/components/core/BaseAccountSwitcher'
    import BaseAppBar from '@framework/components/BaseAppBar'
    import BaseAppFooter from '@framework/components/BaseAppFooter'
    import BaseAppNavigation from '@framework/components/BaseAppNavigation'
    import BaseAppLoading from '@framework/components/BaseAppLoading'
    import BaseForceMfaError from '@framework/components/BaseForceMfaError'

    export default {
        name: 'App',
        mixins: [BaseValidationErrorsMixin],
        metaInfo: {
            title: 'Loading My Windsor',
            titleTemplate: '%s | My Windsor Portal'
        },
        data: () => ({loading: false, pingIntervalId: false, windsorUrls, version: process.env.VERSION}),
        components: {
            AccountMenu,
            BaseAccountSwitcher,
            BaseAppBar,
            BaseAppFooter,
            BaseAppNavigation,
            BaseAppLoading,
            BaseForceMfaError
        },
        computed: {
            ...mapGetters('app', ['navigation', 'title', 'footer']),
            ...mapGetters('confirm', {confirmProps: 'props'}),
            ...mapGetters('auth', [
                'accountRedirectUrl',
                'isAuthed',
                'fromRoute',
                'nextRoute',
                'accountCode',
                'customerId',
                'impersonated',
                'accounts',
                'companyName',
                'token',
                'emailAddress',
                'mfaError',
                'hasMfaError',
                'mfaRedirectUrl'
            ]),
            hasMultipleAccounts () {
                return this.accounts?.length > 1
            }
        },
        methods: {
            ...mapActions('auth', ['ping', 'me']),
            ...mapActions('confirm', ['closeConfirm', 'submitConfirm']),
            handleLogout () {
                this.$router.push({name: LOGOUT})
            },
            async handleChangeAccount (customerId) {
                const vm = this

                try {
                    vm.loading = true
                    await vm.me({customerId, token: vm.token})
                    window.location.reload()
                } catch (e) {
                    vm.loading = false
                    await vm.handleSubmitErrors(e)
                }
            }
        },
        watch: {
            isAuthed (newVal, oldVal) {
                const vm = this

                if ((newVal && !oldVal) && !vm.pingIntervalId) {
                    vm.pingIntervalId = setInterval(async () => {
                        try {
                            await vm.ping()
                        } catch (e) {
                            if (e && e.status === 401) {
                                vm.handleLogout()
                                clearInterval(vm.pingIntervalId)
                            } else {
                                const message = 'Ping Error'
                                logger.error('[APP]', message, e)
                                if (vm.$sentry) {
                                    vm.$sentry.withScope(scope => {
                                        scope.setExtra('context', e)
                                        vm.$sentry.captureMessage(message)
                                    })
                                }
                            }
                        }
                    }, 30000)
                }
            }
        },
        created () {
            if (!contains(['/login', '/auth-check'], this.$route.path)) {
                this.$router.push({path: `/login${window.location.search}`})
            }
        }
    }
</script>
