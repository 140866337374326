<template>
    <!-- AccountMenu.vue -->
    <base-account-menu :email-address="emailAddress">
        <v-list-item>
            <v-list-item-icon>
                <v-icon color="secondary">mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ companyName }}{{ impersonated ? '*' : '' }}</v-list-item-title>
                <v-list-item-subtitle>{{ accountCode }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="impersonated">Logged in via WIMS</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-for="item in items" :key="item.title" v-bind="item.attrs" v-on="item.on">
            <v-list-item-icon>
                <v-icon :color="item.attrs.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </base-account-menu>
</template>

<script>
    // Components
    import BaseAccountMenu from '@framework/components/BaseAccountMenu'

    export default {
        name: 'AccountMenu',
        extends: BaseAccountMenu,
        props: {
            /**
             * The current account code for the session
             */
            accountCode: {
                type: String,
                required: true
            },
            /**
             * The redirect to the account portal
             */
            accountRedirectUrl: {
                type: String,
                required: true
            },
            /**
             * The current company name for the session
             */
            companyName: {
                type: String,
                required: true
            },
            /**
             * The url for the help and support page on the windsor site
             */
            helpUrl: {
                type: String,
                required: true
            },
            /**
             * Whether the user is being impersonated (Logged in via WIMS)
             */
            impersonated: {
                type: Boolean,
                required: true
            },
            /**
             * The url for the Windsor Telecom status page
             */
            statusPageUrl: {
                type: String,
                required: true
            }
        },
        components: {
            BaseAccountMenu
        },
        computed: {
            items () {
                const vm = this

                return [
                    {
                        attrs: {color: 'primary', href: vm.accountRedirectUrl, target: '_blank'},
                        on: {},
                        icon: 'mdi-account',
                        title: 'My Account'
                    },
                    {
                        attrs: {color: 'info', href: vm.helpUrl, target: '_blank'},
                        on: {},
                        icon: 'mdi-help-circle',
                        title: 'Help & Support'
                    },
                    {
                        attrs: {color: 'info', href: vm.statusPageUrl, target: '_blank'},
                        on: {},
                        icon: 'mdi-server',
                        title: 'Status Page'
                    },
                    {
                        attrs: {color: 'error'},
                        on: {click: vm.handleClickLogout},
                        icon: 'mdi-exit-to-app',
                        title: 'Logout'
                    }
                ]
            }
        },
        methods: {
            handleClickLogout () {
                /**
                 * @event click:logout
                 */
                this.$emit('click:logout')
            }
        }
    }
</script>
