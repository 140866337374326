import baseAppModule from '@framework/store/modules/app'
import routeNames from '@/router/route-names'
import {APP_FOOTER, APP_TITLE} from '@/store/static/base-app'
import {CLOUD_TELEPHONY} from '@/store/static/namespaces'

const baseApp = baseAppModule()

const initialState = () => ({
    ...baseApp.state,
    title: APP_TITLE,
    footer: APP_FOOTER,
    routeNames
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        ...baseApp.getters,
        navigation: (state, getters, rootState, rootGetters) => [
            {
                icon: 'mdi-cloud',
                title: 'My Cloud Telephony',
                to: {path: `/${CLOUD_TELEPHONY}`},
                meta: {subtitle: 'My Cloud Telephony'},
                exact: false,
                children: [
                    {
                        icon: 'mdi-deskphone',
                        title: 'Cloud Telephony Portal',
                        href: '/api/cloud-telephony/auth',
                        target: '_blank'
                    },
                    {
                        icon: 'mdi-dialpad',
                        title: 'Extensions',
                        exact: false,
                        to: {name: routeNames.cloudTelephony.extensions.list}
                    },
                    {
                        icon: 'mdi-account-group',
                        title: 'Call Manager Plus',
                        exact: false,
                        to: {name: routeNames.cloudTelephony.contactCentre.home}
                    },
                ]
            },
            {
                icon: 'mdi-help-circle-outline',
                title: 'Help & Support',
                href: process.env.VUE_APP_KNOWLEDGE_BASE_URL,
                target: '_blank'
            },
            {
                icon: 'mdi-open-in-new',
                title: 'My Windsor',
                href: rootGetters['auth/myWindsorRedirectUrl'],
                target: '_blank'
            }
        ],
        title: state => state.title,
        footer: state => state.footer
    },
    actions: {
        ...baseApp.actions
    },
    mutations: {
        ...baseApp.mutations
    }
}
