import routeNames from './route-names'

const Enforce2FA = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/enforce-2fa/Enforce2FA')
// const ManageStatus = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/statuses/modules/ManageStatus')
// const RemoveStatuses = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/statuses/modules/RemoveStatuses')

export default {
    path: 'enforce-2fa',
    name: routeNames.list,
    meta: {title: 'Enforce 2FA'},
    component: Enforce2FA,
    props: true,
}
