export const CRISIS = {
    text: 'Warning',
    value: 'crisis',
    color: 'crisis'
}

export const ACTIONABLE_BAD = {
    text: 'Poor',
    value: 'actionable_bad',
    color: `${CRISIS.color} lighten-3`
}

export const GOOD = {
    text: 'Excellent',
    value: 'good',
    color: 'good'
}

export const ACTIONABLE_GOOD = {
    text: 'Good',
    value: 'actionable_good',
    color: `${GOOD.color} lighten-3`
}

export default {
    CRISIS,
    ACTIONABLE_BAD,
    ACTIONABLE_GOOD,
    GOOD
}
