import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'

const initialState = () => ({
    wallboardCard: {
        uuid: null,
        orderId: null,
        title: null,
        stat_unit: null,
        stat_name: null,
        created_at: null,
        updated_at: null,
        disable_thresholds: false,
        values: {
            crisis: null,
            actionable_bad: null,
            actionable_good: null,
            good: null
        }
    }
})

const api = apiModule
    .cloudTelephony
    .contactCentre
    .centre
    .wallboards
    .wallboard
    .cards
    .card

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        wallboardCard: state => state.wallboardCard,
        wallboardCardUuid: state => state.wallboardCard.uuid
    },
    actions: {
        showWallboardCard: async ({commit, dispatch}, data) => {
            try {
                const {card} = await api.showWallboardCard(data)
                commit(mutationTypes.SET_WALLBOARD_CARD, card)
                return card
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        createWallboardCard: async ({dispatch}, data) => {
            try {
                const response = await api.createWallboardCard(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        updateWallboardCard: async ({dispatch}, data) => {
            try {
                const response = await api.updateWallboardCard(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        reorderWallboardCards: async ({dispatch}, data) => {
            try {
                const response = await api.reorderWallboardCards(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeWallboardCards: async ({dispatch}, data) => {
            try {
                const response = await api.removeWallboardCards(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetWallboardCard: ({commit}) => commit(mutationTypes.RESET_WALLBOARD_CARD)
    },
    mutations: {
        [mutationTypes.SET_WALLBOARD_CARD]: (state, data) => state.wallboardCard = data,
        [mutationTypes.RESET_WALLBOARD_CARD]: state => Object.assign(state, initialState())
    }
}
