import client from '@/http'

/**
 * Auto Logout API endpoints
 */
export default {
    /**
     * @param {string} uuid
     * @returns {Promise<any>}
     */
    async showAutoSignOut (uuid) {
        try {
            const response = await client.get(`contact-centre/auto-logouts/${uuid}`)
            return response.data.auto_logout
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{description: string, time: string, days: [], agent_uuids: [], queue_uuids: [], global: boolean}} data
     * @returns {Promise<any>}
     */
    async createAutoSignOut (data) {
        try {
            const response = await client.post('contact-centre/auto-logouts', data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuid: string, description: string, time: string, days: [], agent_uuids: [], queue_uuids: [], global: boolean}} data
     * @returns {Promise<any>}
     */
    async updateAutoSignOut (data) {
        try {
            const response = await client.put(`contact-centre/auto-logouts/${data.uuid}`, data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {string[]} uuids
     * @returns {Promise<any>}
     */
    async removeAutoSignOuts (uuids) {
        try {
            const response = await client.delete(`contact-centre/auto-logouts`, {
                data: {uuids}
            })
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
