import client from '@/http'
// Modules
import extension from './modules/extension'

/**
 * Extensions API endpoints
 */
export default {
    // Modules
    extension,
    // Extensions API
    async listExtensions (data) {
        try {
            const response = await client.get('/extensions', {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },

    async listExtensionsWithSSO(data) {
        try {
            const response = await client.get('/extensions/sso', { params: data })
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
