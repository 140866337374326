import WebsocketClient, {EVENTS} from '@ws-client'
import * as protocol from '@ws-client/protocol'
import {contains} from 'underscore'
import auth from '@/store/api/modules/auth'
import * as wsMutations from '@/store/modules/websocket/mutation-types'
import {FORCE_CLOSED} from '@ws-client/close-reasons'
import {SET_RETRY_ATTEMPTS} from '@framework/store/modules/connections/mutation-types'
import {CC_WEBSOCKET} from '@/store/static/cloud-telephony/connection-types'
import {ADD_AGENT_STATUS, REMOVE_AGENT_STATUS, RESET_AGENT, SET_AGENT, SET_AGENT_SIGN_IN, SET_AGENT_SIGN_OUT, SET_AGENT_STATUS, UPDATE_AGENT_STATUS_TIME} from '@/store/modules/cloud-telephony/modules/contact-centre/modules/centre/modules/agents/modules/agent/mutation-types'
import {SET_AGENTS, SET_AGENTS_STATUSES, UPDATE_AGENT_SIGNED_IN, UPDATE_AGENT_STATUS} from '@/store/modules/cloud-telephony/modules/contact-centre/modules/centre/modules/agents/mutation-types'
import {SET_STATUS_LIST} from '@/store/modules/cloud-telephony/modules/contact-centre/modules/centre/mutation-types'
// eslint-disable-next-line no-unused-vars
import {Store} from 'vuex'
import * as Sentry from '@sentry/browser'

/**
 * @type {*[]}
 */
let agentStatuses = []

/**
 * @type {Store}
 */
let store

/**
 * @type {WebsocketClient}
 */
let client

/**
 * @type {number|undefined}
 */
let intervalId = undefined

/**
 * Send the C2S_AgentSignIn packet to the server
 * The agent should be set to ready and the signed in flag set to true
 * @param {{agentUuid: string}} data
 */
function handleAgentSignIn (data) {
    return client.sendPacket(protocol.C2S_AgentSignIn, data)
}

/**
 * Update the is_signed_in flag for the agent in agents store
 * @param {{agentUuid: string}} data
 */
function handleAgentSignedIn (uuid, value = true) {
    store.commit(centrePath(`agents/${UPDATE_AGENT_SIGNED_IN}`), {
        uuid,
        value
    })
}

/**
 * Send the C2S_AgentSignOut packet to the server
 * The agent should be set to not ready and the signed in flag set to false
 * @param {{agentUuid: string}} data
 */
function handleAgentSignOut (data) {
    return client.sendPacket(protocol.C2S_AgentSignOut, data)
}

/**
 * Send the C2S_FetchExtensionStatuses packet to the server
 * The websocket should then receive a S2C_MultiStatusChange packet
 */
function handleFetchExtensionStatuses () {
    return client.sendPacket(protocol.C2S_FetchExtensionStatuses)
}

/**
 * Reset the auth store when the connection is accepted
 */
function handleConnectionAccepted () {
    store.commit(`websocket/${wsMutations.RESET_WEBSOCKET_AUTH}`)
    store.commit(`websocket/${wsMutations.SET_WEBSOCKET_AUTHENTICATED}`, true)
    handleMultiStatusChange()
}

/**
 * Set connected state in store
 */
function handleConnected () {
    store.commit(
        `websocket/${wsMutations.SET_CONNECTED}`,
        true
    )

    store.dispatch(`connections/setConnected`, {
        type: CC_WEBSOCKET,
        value: true
    })
}

/**
 * Handle websocket disconnection
 * @param {{
 * event: CloseEvent,
 * error: {
 *  code: number,
 *  message: string,
 *  info: string,
 *  ref: string,
 *  status: number
 * },
 * isAuthClosure: boolean,
 * shouldRetry: boolean,
 * wasConnected: boolean
 * }} data
 * @returns {Promise<any>|void}
 */
function handleDisconnected (data) {
    // Set the websocket to disconnected in the websocket store
    store.commit(
        `websocket/${wsMutations.SET_CONNECTED}`,
        false
    )

    // If the connection was closed deliberately do nothing
    if (data.error.code === FORCE_CLOSED) {
        return
    }

    // If we were connected, and have now lost connection then start connections monitor retry interval
    if (data.shouldRetry) {
        return store.dispatch(`connections/setConnected`, {
            type: CC_WEBSOCKET,
            value: false
        })
    }

    // If auth issue then show authentication error
    if (data.isAuthClosure && data.wasConnected) {
        store.dispatch(`connections/resetConnections`)
        // If auth issue then show authentication error
        return store.commit(
            `websocket/${wsMutations.SET_WEBSOCKET_AUTH_ERROR}`,
            data.error
        )
    }

    const connections = store.getters['connections/connections']
    const connectionStatus = connections[CC_WEBSOCKET]
    const isRetryingConnection = connectionStatus.retryInterval

    // If we lost connection and are not retrying show error
    if (!isRetryingConnection) {
        store.commit(
            `websocket/${wsMutations.SET_WEBSOCKET_AUTH_ERROR}`,
            data.error
        )
    }
}

/**
 * Process the S2C_MultiStatusChange packet and set statuses for extensions
 * currently in the store
 * @param {{statuses: []}|undefined} data
 */
function handleMultiStatusChange (data) {
    agentStatuses = (data && data.statuses) || agentStatuses
    let statusesToUpdate = []

    agentStatuses.forEach(status => {
        const agentVisible = isAgentVisible(status.extension)

        if (agentVisible.list) {
            statusesToUpdate.push(status)
        }

        if (agentVisible.show) {
            setAgentStatus(status)
        }
    })

    store.commit(
        centrePath(`agents/${SET_AGENTS_STATUSES}`),
        statusesToUpdate
    )
}

/**
 * Process the S2C_StatusChange packet and set the status in store if extension
 * is currently in the store
 * @param {object} data
 */
function handleStatusChange (data) {
    const agentVisible = isAgentVisible(data.extension)

    if (agentVisible.list) {
        store.commit(
            centrePath(`agents/${UPDATE_AGENT_STATUS}`),
            data
        )
    }

    if (agentVisible.show) {
        setAgentStatus(data)
    }
}

/**
 * Send the C2S_StateChange packet to add new status to the agent state queue
 * @param {object} data
 */
function handlePushAgentStatus (data) {
    client.sendPacket(protocol.C2S_PushStatus, data)
}

/**
 * Send the C2S_PopStateChange packet to delete status from the agent state queue
 * @param {object} data
 */
function handlePopAgentStatus (data) {
    client.sendPacket(protocol.C2S_PopStatus, data)
}

/**
 * Set the status list in the centre store module
 * @param {array} data
 */
function handleStatusList (data) {
    store.commit(centrePath(SET_STATUS_LIST), data.statuses)
}

/**
 * Checks if agent is currently set in the agents store (list) or in the agent
 * store (show)
 * @param {number} extension
 * @returns {{show: boolean, list: boolean}}
 */
function isAgentVisible (extension) {
    const agentsExtensions = store.getters[centrePath('agents/agentsExtensions')]
    const selectedAgentExtension = store.getters[centrePath('agents/agent/agentExtension')]

    return {
        list: contains(agentsExtensions, extension),
        show: selectedAgentExtension === extension
    }
}

/**
 * @returns {Promise<void>}
 */
async function processMessage (payload = {}) {
    const {data, name} = payload

    switch (name) {
        case protocol.S2C_ConnectionAccepted:
            return handleConnectionAccepted()
        case protocol.S2C_MultiStatusChange:
            return handleMultiStatusChange(data)
        case protocol.S2C_StatusChange:
            return handleStatusChange(data)
        case protocol.S2C_StatusList:
            return handleStatusList(data)
        case protocol.S2C_AgentSignedIn:
            return handleAgentSignedIn(data.agentUuid, true)
        case protocol.S2C_AgentSignedOut:
            return handleAgentSignedIn(data.agentUuid, false)
        default:
            return null
    }
}

/**
 * Set the currently selected agent status in the store
 * @param {object} data
 */
function setAgentStatus (data) {
    const agentNamespace = centrePath('agents/agent')

    store.commit(`${agentNamespace}/${SET_AGENT_STATUS}`, data)
    store.commit(`${agentNamespace}/${UPDATE_AGENT_STATUS_TIME}`)

    if (intervalId) {
        clearInterval(intervalId)
    }

    intervalId = setInterval(() => {
        store.commit(`${agentNamespace}/${UPDATE_AGENT_STATUS_TIME}`)
    }, 1000)
}

/**
 * Start the WebsocketClient and bind event handlers
 */
async function startWebsocket () {
    let url = process.env.VUE_APP_CC_WEBSOCKET_URL
    /**
     * @type {{token: string, customer_id: number|string}}
     */
    let user

    try {
        user = await auth.me()
    } catch (e) {
        if (Sentry) {
            Sentry.withScope(scope => {
                scope.setExtra('context', e)
                Sentry.captureMessage('Error Starting Websocket')
            })
        }

        throw e
    }

    url += `?auth=${user.token}&customer_id=${user.customer_id}`

    client = new WebsocketClient(url)

    // On connected set the connected state in the store
    client.on(EVENTS.CONNECTED, handleConnected)

    // On connected set the connected state in the store
    client.on(EVENTS.DISCONNECTED, handleDisconnected)

    // On message bind the processMessage handler
    client.on(EVENTS.MESSAGE, processMessage)
}

/**
 * @param {string} path
 */
function centrePath (path) {
    return `cloudTelephony/contactCentre/centre/${path}`
}

/**
 * Default export is a factory method which is used by the Vuex plugin system
 * When the `SET_WEBSOCKET_AUTH` mutation is called, an attempt to connect the
 * websocket is made.
 *
 * @param {Store} storeInstance
 */
export default storeInstance => {
    store = storeInstance

    store.subscribe((mutation) => {
        const {payload, type} = mutation

        switch (type) {
            case `websocket/${wsMutations.SET_WEBSOCKET_AUTH}`:
                return startWebsocket(payload)
            case `websocket/${wsMutations.RESET_WEBSOCKET}`:
                return client?.closeConnection()
            case `connections/${SET_RETRY_ATTEMPTS}`:
                return payload.type === CC_WEBSOCKET
                    && payload.value > 0
                    && client?.startConnection()
            case centrePath(`agents/agent/${ADD_AGENT_STATUS}`):
                return handlePushAgentStatus(payload)
            case centrePath(`agents/agent/${REMOVE_AGENT_STATUS}`):
                return handlePopAgentStatus(payload)
            case centrePath(`agents/agent/${RESET_AGENT}`):
                return intervalId && clearInterval(intervalId)
            case centrePath(`agents/agent/${SET_AGENT_SIGN_IN}`):
                return handleAgentSignIn(payload)
            case centrePath(`agents/agent/${SET_AGENT_SIGN_OUT}`):
                return handleAgentSignOut(payload)
            case centrePath(`agents/${SET_AGENTS}`):
            case centrePath(`agents/agent/${SET_AGENT}`):
                return handleFetchExtensionStatuses()
            default:
                return null
        }
    })
}
