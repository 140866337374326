import {mapPagination} from '@windsor-telecom-management/client-core/src/config/tables'
import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'
// Modules
import status from './modules/status'

const api = apiModule.cloudTelephony.contactCentre.centre.statuses

const initialState = () => ({
    statuses: {
        data: [],
        pagination: {}
    }
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        statuses: state => state.statuses
    },
    actions: {
        listStatuses: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listStatuses(data)
                commit(mutationTypes.SET_STATUSES, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse)
            }
        },
        resetStatuses: ({commit}) => commit(mutationTypes.RESET_STATUSES)
    },
    mutations: {
        [mutationTypes.SET_STATUSES]: (state, data) => {
            state.statuses.data = data.data
            state.statuses.pagination = mapPagination(data.pagination)
        },
        [mutationTypes.RESET_STATUSES]: state => Object.assign(state, initialState())
    },
    modules: {
        status
    }
}
