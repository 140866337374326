import cloudTelephony from './modules/cloud-telephony/route-names'

export const LOGIN = 'login'
export const LOGOUT = 'logout'

export default {
    login: LOGIN,
    logout: LOGOUT,
    // Modules
    cloudTelephony
}
