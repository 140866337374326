<template>
    <!-- BaseAccountSwitcher.vue -->
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <v-autocomplete
                    :color="color"
                    :items="accountsSorted"
                    :menu-props="{'contentClass': 'account-switcher'}"
                    :prepend-inner-icon="icon"
                    :style="{'max-width': `${maxWidth}px`}"
                    :value="value"
                    flat
                    hide-details
                    item-text="account_code"
                    item-value="customer_id"
                    @change="handleChange"
                >
                    <template v-slot:item="{item, on, attrs}">
                        <v-list-item
                            :color="listColor"
                            :value="item.account_code"
                            two-line
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}{{ item.impersonated ? '*' : '' }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.account_code }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="item.impersonated">Logged in via WIMS</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </div>
        </template>
        Switch Account
    </v-tooltip>
</template>

<style type="text/css">
    .account-switcher .v-list {
        padding-top: 0;
        padding-bottom: 0;
    }
</style>

<script>
    export default {
        name: 'BaseAccountSwitcher',
        props: {
            /**
             * The array of available accounts for the user i.e. [{name: string, account_code: string}]
             */
            accounts: {
                type: Array,
                default: () => []
            },
            /**
             * The color of the activator button
             */
            color: {
                type: String,
                default: 'primary'
            },
            /**
             * The icon of the activator button
             */
            icon: {
                type: String,
                default: 'mdi-account-arrow-right'
            },
            /**
             * The color of the list
             */
            listColor: {
                type: String,
                default: 'primary'
            },
            /**
             * The max width of the input in pixels
             */
            maxWidth: {
                type: Number,
                default: 150
            },
            /**
             * The active account
             */
            value: {
                type: [Number, String],
                required: true
            }
        },
        computed: {
            accountsSorted () {
                const accounts = this.accounts ?? []
                return accounts.sort((a, b) => b.impersonated - a.impersonated)
            }
        },
        methods: {
            handleChange (value) {
                /**
                 * @event change:account
                 * @type {string}
                 */
                this.$emit('change:account', value)
            }
        }
    }
</script>
