import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'

const RING_STRATEGIES = {
    RINGALL: 'RINGALL',
    LINEAR: 'LINEAR',
    RRMEMORY: 'RRMEMORY',
    FEWESTCALLS: 'FEWESTCALLS',
    LEASTRECENT: 'LEASTRECENT',
    RANDOM: 'RANDOM'
}

const initialState = () => ({
    queue: {
        name: null,
        settings: {
            strategy: null,
            ring_timeout: null,
            ring_retry_interval: null,
            play_ringtone: null,
            announce_queue_position: null,
            announce_queue_position_interval: null,
            maximum_hold_time: null
        }
    },
    ringStrategies: new Map([
        [RING_STRATEGIES.RINGALL, 'Attempt all users simultaneously'],
        [RING_STRATEGIES.LINEAR, 'Attempt users in order from first target everytime'],
        [RING_STRATEGIES.RRMEMORY, 'Loop in order from first user everytime'],
        [RING_STRATEGIES.FEWESTCALLS, 'Attempt users in order based on least number of calls'],
        [RING_STRATEGIES.LEASTRECENT, 'Attempt users in order based on time since last call'],
        [RING_STRATEGIES.RANDOM, 'Attempt users randomly']
    ])
})

const api = apiModule.cloudTelephony.contactCentre.centre.queues.queue

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        queue: state => state.queue,
        queueUuid: state => state.queue && state.queue.uuid,
        queueSettings: state => ({
            name: state.queue.name,
            ...state.queue.settings
        }),
        queueRingStrategies: state => {
            let items = []
            state.ringStrategies.forEach((text, value) => {
                items.push({text, value})
            })
            return items
        }
    },
    actions: {
        createQueue: async ({dispatch}, data) => {
            try {
                const response = await api.createQueue(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeQueues: async ({dispatch}, uuids) => {
            try {
                const response = await api.removeQueues(uuids)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        showQueue: async ({commit, dispatch}, uuid) => {
            try {
                const response = await api.showQueue(uuid)
                commit(mutationTypes.SET_QUEUE, response.queue)
                return response.queue
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        updateQueue: async ({commit, dispatch}, data) => {
            try {
                const response = await api.updateQueue(data)
                commit(mutationTypes.SET_QUEUE, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        updateQueueSettings: async ({dispatch}, data) => {
            try {
                return await api.updateQueueSettings(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        addAgentsToQueue: async ({dispatch}, data) => {
            try {
                const response = await api.addAgentsToQueue(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeAgentsFromQueue: async ({dispatch}, data) => {
            try {
                const response = await api.removeAgentsFromQueue(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetQueue: ({commit}) => commit(mutationTypes.RESET_QUEUE)
    },
    mutations: {
        [mutationTypes.SET_QUEUE]: (state, data) => state.queue = data,
        [mutationTypes.RESET_QUEUE]: state => Object.assign(state, initialState())
    }
}
