import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'
// Modules
import user from './modules/user'

const initialState = () => ({
    extension: {
        id: null,
        extension: null,
        name: null,
        sso: null
    }
})

const api = apiModule.cloudTelephony.extensions.extension

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        extension: state => state.extension,
        extensionId: state => (state.extension && parseInt(state.extension.id)) || null,
        extensionTitle: state => `${state.extension.extension} - ${state.extension.name}`
    },
    actions: {
        showExtension: async ({commit, dispatch}, id) => {
            try {
                const response = await api.showExtension(id)
                commit(mutationTypes.SET_EXTENSION, response)
                dispatch(`user/setUser`, response.user)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetExtension: ({commit, dispatch}) => {
            commit(mutationTypes.RESET_EXTENSION)
            dispatch('user/resetUser')
        }
    },
    mutations: {
        [mutationTypes.SET_EXTENSION]: (state, data) => state.extension = data,
        [mutationTypes.RESET_EXTENSION]: state => Object.assign(state, initialState())
    },
    modules: {
        user
    }
}
