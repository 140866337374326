import client from '@/http'
import wallboard from './modules/wallboard'

/**
 * Centre Wallboards API endpoints
 */
export default {
    // Modules
    wallboard,
    // Centre Wallboards API
    async listWallboards (data) {
        try {
            const response = await client
                .get('/contact-centre/wallboards', {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
