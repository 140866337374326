import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'

const api = apiModule.cloudTelephony.contactCentre.extensions

const initialState = () => ({
    extensions: {
        data: [],
        pagination: {}
    },
    unassignedExtensions: {
        data: []
    }
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        extensions: state => state.extensions,
        unassignedExtensions: state => state.unassignedExtensions
    },
    actions: {
        searchExtensions: async ({commit, dispatch}, data) => {
            try {
                const response = await api.searchExtensions(data)
                commit(mutationTypes.SET_EXTENSIONS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        searchFullExtensions: async ({commit, dispatch}, data) => {
            try {
                const response = await api.searchFullExtensions(data)
                commit(mutationTypes.SET_EXTENSIONS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        searchUnassignedExtensions: async ({commit, dispatch}, data) => {
            try {
                const response = await api.searchUnassignedExtensions(data)
                commit(mutationTypes.SET_UNASSIGNED_EXTENSIONS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetExtensions: ({commit}) => commit(mutationTypes.RESET_EXTENSIONS)
    },
    mutations: {
        [mutationTypes.SET_EXTENSIONS]: (state, data) => state.extensions = data,
        [mutationTypes.SET_UNASSIGNED_EXTENSIONS]: (state, data) => state.unassignedExtensions = data,
        [mutationTypes.RESET_EXTENSIONS]: state => Object.assign(state, initialState())
    }
}
