import * as mutationTypes from './mutation-types'
import {isEmpty} from 'underscore'
// API
import apiModule from '@/store/api'

const initialState = () => ({
    user: {}
})

const api = apiModule.cloudTelephony.extensions.extension.user

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        user: state => state.user,
        hasUser: state => !isEmpty(state.user),
        userEmailAddress: state => state.user && state.user.sso && state.user.sso.email_address
    },
    actions: {
        assignUser: async ({dispatch}, data) => {
            try {
                return await api.assignUser(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        cancelUserInvite: async ({dispatch}, data) => {
            try {
                return await api.cancelUserInvite(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        disableAccess: async ({dispatch}, data) => {
            try {
                return await api.disableAccess(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        enableAccess: async ({dispatch}, data) => {
            try {
                return await api.enableAccess(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resendUserInvite: async ({dispatch}, data) => {
            try {
                return await api.resendUserInvite(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        sendPasswordReset: async ({dispatch}, data) => {
            try {
                return await api.sendPasswordReset(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        unassignUser: async ({dispatch}, data) => {
            try {

                //Setting default values for the user
                await api.enforce2FA({
                    emails : [ data.email ],
                    enforce : false
                })

                await api.enableEmailFallback({
                    emails : [ data.email ],
                    enable : true
                })

                await api.enableSMSFallback({
                    emails : [ data.email ],
                    enable : true
                })

                return await api.unassignUser(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        enforce2FA: async ({dispatch}, data) => {
            try {
                return await api.enforce2FA(data)
            }
            catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        disable2fa: async ({dispatch}, data) => {
            try {
                return await api.disable2fa(data)
            }
            catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        enableEmailFallback: async ({dispatch}, data) => {
            try {
                return await api.enableEmailFallback(data)
            }
            catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        enableSMSFallback: async ({dispatch}, data) => {
            try {
                return await api.enableSMSFallback(data)
            }
            catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        setUser: ({commit}, data) => commit(mutationTypes.SET_USER, data),
        resetUser: ({commit}) => commit(mutationTypes.RESET_USER)
    },
    mutations: {
        [mutationTypes.SET_USER]: (state, data) => state.user = data,
        [mutationTypes.RESET_USER]: state => Object.assign(state, initialState())
    }
}
