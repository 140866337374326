export const STATUSES = 'STATUSES'
export const STATUSES_CREATE = 'STATUSES_CREATE'
export const STATUSES_UPDATE = 'STATUSES_UPDATE'
export const STATUSES_REMOVE = 'STATUSES_REMOVE'
export const STATUSES_BULK_REMOVE = 'STATUSES_BULK_REMOVE'

export default {
    list: STATUSES,
    create: STATUSES_CREATE,
    update: STATUSES_UPDATE,
    remove: STATUSES_REMOVE,
    bulkRemove: STATUSES_BULK_REMOVE
}
