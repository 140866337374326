import * as mutationTypes from './mutation-types'
import agentStatuses from '@framework/store/static/agent-statuses'
import {findWhere} from 'underscore'
// API
import apiModule from '@/store/api'
// Modules
import agents from './modules/agents'
import autoSignOuts from './modules/auto-sign-outs'
import queues from './modules/queues'
import reporting from './modules/reporting'
import scheduledReports from './modules/scheduled-reports'
import statuses from './modules/statuses'
import wallboards from './modules/wallboards'

const initialState = () => ({
    centre: {
        uuid: null,
        name: null,
        wrap_up: {
            name: null,
            duration: 0
        }
    },
    token: null,
    statusList: []
})

const api = apiModule.cloudTelephony.contactCentre.centre

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        centre: state => state.centre,
        centreUuid: state => state.centre && state.centre.uuid,
        statusList: state => state.statusList,
        statusListFormatted: state => state.statusList.map(status => ({
            text: status.name,
            value: status.uuid
        })),
        mapAgentStatus: state => (status = {}) => {
            const centreStatus = findWhere(state.statusList, {uuid: status.uuid})

            if (!centreStatus) {
                return {}
            }

            return {
                ...centreStatus,
                ...status,
                ...agentStatuses.getStatusDetails(centreStatus.statusType)
            }
        },
        wrapUp: state => state.centre && state.centre.wrap_up
    },
    actions: {
        showCentre: async ({commit, dispatch}) => {
            try {
                const response = await api.showCentre()
                const {centre} = response
                commit(mutationTypes.SET_CENTRE, centre)
                return centre
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        updateCentre: async ({dispatch}, data) => {
            try {
                return await api.updateCentre(data)
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetCentre: ({commit}) => commit(mutationTypes.RESET_CENTRE)
    },
    mutations: {
        [mutationTypes.SET_CENTRE]: (state, data) => state.centre = data,
        [mutationTypes.SET_TOKEN]: (state, data) => state.token = data,
        [mutationTypes.SET_STATUS_LIST]: (state, data) => state.statusList = data,
        [mutationTypes.RESET_CENTRE]: state => Object.assign(state, initialState())
    },
    modules: {
        agents,
        autoSignOuts,
        queues,
        reporting,
        statuses,
        scheduledReports,
        wallboards
    }
}
