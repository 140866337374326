import routeNames from './route-names'

const AutoSignOuts = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/auto-sign-outs/AutoSignOuts')
const ManageAutoSignOut = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/auto-sign-outs/modules/ManageAutoSignOut')
const RemoveAutoSignOuts = () => import(/* webpackChunkName: "auto-sign-outs" */ '@/views/modules/cloud-telephony/modules/contact-centre/modules/settings/modules/auto-sign-outs/modules/RemoveAutoSignOuts')

export default {
    path: 'auto-sign-outs',
    name: routeNames.list,
    meta: {title: 'Auto Sign Out'},
    component: AutoSignOuts,
    props: true,
    children: [
        {
            path: 'create',
            name: routeNames.create,
            meta: {title: 'Create Configuration'},
            props: (route) => ({
                ...route.params,
                redirect: {
                    name: routeNames.list
                }
            }),
            component: ManageAutoSignOut
        },
        {
            path: 'update/:autoSignOutUuid',
            name: routeNames.update,
            meta: {title: 'Update Configuration'},
            props: (route) => ({
                ...route.params,
                redirect: {
                    name: routeNames.list
                }
            }),
            component: ManageAutoSignOut
        },
        {
            path: 'remove/multiple',
            name: routeNames.bulkRemove,
            meta: {title: 'Remove Configurations'},
            props: (route) => ({
                ...route.params,
                bulk: true
            }),
            component: RemoveAutoSignOuts
        },
        {
            path: 'remove/:autoSignOutUuid',
            name: routeNames.remove,
            meta: {title: 'Remove Configuration'},
            props: true,
            component: RemoveAutoSignOuts
        }
    ]
}
