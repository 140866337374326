import autoSignOuts from './modules/auto-signouts/route-names'
import scheduledReports from './modules/scheduled-reports/route-names'
import statuses from './modules/statuses/route-names'
import enforce2FA from './modules/enforce-2fa/route-names'

export const SETTINGS = 'SETTINGS'

export default {
    show: SETTINGS,
    autoSignOuts,
    scheduledReports,
    statuses,
    enforce2FA
}
