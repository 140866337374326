<template>
    <!-- WallboardCardForm.vue -->
    <v-card class="mx-auto" data-test="wallboardCardForm">
        <!-- Form Toolbar -->
        <base-form-toolbar
            :loading="loading"
            :title="title"
            @close="handleSecondaryFormEvent"
        ></base-form-toolbar>

        <!-- Form -->
        <base-form-container :loading="loading">
            <v-form ref="form" v-model="formValid" class="px-3" @submit.prevent>
                <!-- Form Errors -->
                <base-form-alert
                    :errors="formErrorsAlert.content"
                    :value="formErrorsAlert.show"
                ></base-form-alert>
                <!-- Title -->
                <v-text-field
                    ref="title"
                    :error-messages="fieldErrors('title')"
                    :rules="[validationRules.generic.required]"
                    :value="formData.title"
                    autocomplete="off"
                    data-test="titleInput"
                    dense
                    hint="The title of the wallboard card"
                    label="Name"
                    outlined
                    @focus="clearFieldErrors('title')"
                    @input="(val) => setFieldData('title', val)"
                    @click:prepend="() => handlePrependClick('title')"
                    @keyup.enter="handleSubmit"
                ></v-text-field>
                <!-- Stat Name  -->
                <v-autocomplete
                    data-test="statNameInput"
                    :items="cardTypes.data"
                    :rules="[validationRules.generic.required]"
                    :loading="cardTypes.loading"
                    :value="formData.stat_name"
                    dense
                    label="Type"
                    outlined
                    return-object
                    @input="handleSelectCardType"
                    @update:search-input="handleSearchCardTypes"
                ></v-autocomplete>
                <!-- Stat Unit  -->
                <v-autocomplete
                    data-test="statUnitInput"
                    :disabled="!hasUnits"
                    :rules="[validationRules.generic.required]"
                    :hint="!hasUnits ? 'Select a type first' : ''"
                    :persistent-hint="!hasUnits"
                    :items="units"
                    :loading="cardTypes.loading"
                    :value="formData.stat_unit"
                    dense
                    label="Unit"
                    outlined
                    @input="val => setFieldData('stat_unit', val)"
                ></v-autocomplete>
                <!-- Threshold Toggle -->
                <v-checkbox
                    :input-value="!formData.disable_thresholds"
                    class="mb-3 mt-0"
                    color="success"
                    data-test="thresholdsCheckbox"
                    hint="All disabled cards will display blue."
                    label="Enable Thresholds"
                    persistent-hint
                    @change="val => setFieldData('disable_thresholds', !val)"
                ></v-checkbox>
                <template v-if="!formData.disable_thresholds">
                    <!-- Thresholds Description -->
                    <v-card-text
                        class="accent--text text-body-1 fill-height px-1 pt-0 pb-1"
                        data-test="thresholdsDescription"
                    >
                        Configure the thresholds below to measure how your metric is doing. These thresholds
                        determine the colour of the card on the wallboard.
                    </v-card-text>
                    <!-- Thresholds -->
                    <v-container data-test="thresholdsContainer" fluid pa-0>
                        <v-row v-for="(threshold, i) in thresholds" :key="`threshold_${i}`" no-gutters>
                            <!-- Threshold -->
                            <v-col
                                v-for="(thresholdItem, n) in threshold"
                                :key="`thresholdItem_${n}`"
                                v-bind="item.grid"
                            >
                                <!-- Info Item -->
                                <v-card
                                    v-if="thresholdItem.info"
                                    :color="thresholdItem.color"
                                    class="pa-0 rounded-0 fill-height"
                                    flat
                                >
                                    <v-row class="align-content-center fill-height" no-gutters>
                                        <v-col align-self="center">
                                            <v-card-text class="white--text text-body-1 fill-height text-center">
                                                {{ thresholdItem.description }}
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-card>
                                <!-- Threshold Item -->
                                <v-card
                                    v-else
                                    :color="thresholdItem.color"
                                    class="rounded-0 pb-0 fill-height d-flex flex-column"
                                    flat
                                >
                                    <v-card-title class="white--text py-1">
                                        {{ thresholdItem.title }}
                                    </v-card-title>
                                    <v-card-text class="white--text text-body-1 py-1">
                                        {{ thresholdItem.description }}
                                    </v-card-text>
                                    <v-spacer></v-spacer>
                                    <v-card-actions v-if="!thresholdItem.hideValue" class="pt-1">
                                        <v-sheet class="mx-auto">
                                            <!-- Threshold Value -->
                                            <v-text-field
                                                :data-test="`${thresholdItem.key}ThresholdValue`"
                                                :rules="[validationRules.generic.required, validationRules.generic.minInt(0)]"
                                                :value="thresholdItem.value"
                                                autocomplete="off"
                                                class="mfa text-h5"
                                                dense
                                                filled
                                                full-width
                                                hide-details="auto"
                                                label="Value"
                                                type="number"
                                                @input="val => setFieldData(thresholdItem.key, parseInt(val), 'values')"
                                                @keyup.enter="handleSubmit"
                                            ></v-text-field>
                                        </v-sheet>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </v-form>
        </base-form-container>

        <!-- Form Actions -->
        <base-form-actions-container :loading="loading" class="pb-0">
            <base-form-actions
                :loading="saving"
                :primary-button-disabled="!canSubmit"
                @close="handleSecondaryFormEvent"
                @submit="handleSubmit"
            ></base-form-actions>
        </base-form-actions-container>
    </v-card>
</template>

<style lang="scss">
    .mfa .v-label {
        left: 50% !important;
        transform: translateX(-50%);
    }

    .mfa input {
        text-align: center;
        color: #35424B !important;
    }
</style>

<script>
    import {findWhere, isEmpty} from 'underscore'
    import {ACTIONABLE_BAD, ACTIONABLE_GOOD, CRISIS, GOOD} from '@/store/static/cloud-telephony/wallboard-card-thresholds'
    // Mixins
    import BaseFormMixin from '@framework/mixins/base-form.mixin'

    const formData = () => ({
        title: null,
        disable_thresholds: false,
        values: {
            [CRISIS.value]: 0,
            [ACTIONABLE_BAD.value]: 0,
            [ACTIONABLE_GOOD.value]: 0,
            [GOOD.value]: 0
        },
        stat_name: null,
        stat_unit: null
    })

    export default {
        name: 'WallboardCardForm',
        mixins: [BaseFormMixin],
        data: () => ({
            formData: formData(),
            units: []
        }),
        props: {
            /**
             * The available card types i.e. {data: [], loading: false}
             */
            cardTypes: {
                type: Object,
                required: true
            }
        },
        computed: {
            hasUnits () {
                const vm = this
                return !isEmpty(vm.units)
            },
            thresholds () {
                const vm = this

                return [
                    [
                        {
                            title: ACTIONABLE_GOOD.text,
                            description: `The metric set is deemed good and will display as light green.`,
                            key: ACTIONABLE_GOOD.value,
                            value: vm.formData.values[ACTIONABLE_GOOD.value],
                            color: ACTIONABLE_GOOD.color,
                            grid: {cols: 12, md: 4}
                        },
                        {
                            title: 'Great',
                            hideValue: true,
                            description: `When the metric sits between "Good" and "Excellent" it is displayed
                                as a slightly darker shade of green.`,
                            color: `${GOOD.color} lighten-2`,
                            grid: {cols: 12, md: 4}
                        },
                        {
                            title: GOOD.text,
                            description: `The metric set is deemed excellent and will display as dark green.  Any value
                                above the threshold will display dark green.`,
                            key: GOOD.value,
                            value: vm.formData.values[GOOD.value],
                            color: GOOD.color,
                            grid: {cols: 12, md: 4}
                        }
                    ],
                    [
                        {
                            info: true,
                            description: `The card will display as blue (average) when the metric displayed is not within
                                the threshold limits.`,
                            color: 'info',
                            grid: {cols: 12}
                        }
                    ],
                    [
                        {
                            title: ACTIONABLE_BAD.text,
                            description: `The metric set is deemed poor and will display as light red.`,
                            key: ACTIONABLE_BAD.value,
                            value: vm.formData.values[ACTIONABLE_BAD.value],
                            color: ACTIONABLE_BAD.color,
                            grid: {cols: 12, md: 4}
                        },
                        {
                            title: 'Bad',
                            hideValue: true,
                            description: `When the metric sits between "Poor" and "Warning" it is displayed as
                                a slightly darker shade of red.`,
                            color: `${CRISIS.color} lighten-2`,
                            grid: {cols: 12, md: 4}
                        },
                        {
                            title: CRISIS.text,
                            description: `The metric set is deemed as a warning and will display as dark red.  Any value
                                above the threshold will display dark red.`,
                            key: CRISIS.value,
                            value: vm.formData.values[CRISIS.value],
                            color: CRISIS.color,
                            grid: {cols: 12, md: 4}
                        }
                    ]
                ]
            },
            title () {
                const vm = this
                const description = 'Wallboard Card'
                return vm.item && vm.item.uuid ? `Update ${description}` : `Create ${description}`
            }
        },
        methods: {
            initFormData: formData,
            setFormData () {
                const vm = this
                vm.formData = {
                    ...vm.formData,
                    ...vm.itemFormatted,
                    values: {
                        ...vm.formData.values,
                        ...vm.itemFormatted.values
                    }
                }
            },
            handleSelectCardType ({value, units}) {
                const vm = this
                vm.setFieldData('stat_name', value)

                if (units) {
                    vm.setFieldData('stat_unit', units[0]?.value)
                    vm.units = units
                }
            },
            handleSearchCardTypes (val) {
                /**
                 * @event search:card-types
                 */
                this.$emit('search:card-types', val)
            }
        },
        watch: {
            cardTypes ({data}) {
                const vm = this

                if (!isEmpty(data) && vm.formData.stat_name && isEmpty(vm.units)) {
                    const type = findWhere(data, {value: vm.formData.stat_name})

                    if (type?.units) {
                        vm.units = type.units
                    }
                }
            }
        }
    }
</script>
