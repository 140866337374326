import * as mutationTypes from './mutation-types'
// API
import apiModule from '@/store/api'
// Modules
import cards from './modules/cards'

const initialState = () => ({
    wallboard: {
        card_count: null,
        cards: null,
        created_at: null,
        name: null,
        queue_count: null,
        updated_at: null,
        uuid: null
    }
})

const api = apiModule.cloudTelephony.contactCentre.centre.wallboards.wallboard

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        wallboard: state => state.wallboard,
        wallboardUuid: state => state.wallboard.uuid
    },
    actions: {
        createWallboard: async ({dispatch}, data) => {
            try {
                const response = await api.createWallboard(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        showWallboard: async ({commit, dispatch}, uuid) => {
            try {
                const {wallboard} = await api.showWallboard(uuid)
                commit(mutationTypes.SET_WALLBOARD, wallboard)
                return wallboard
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        addQueuesToWallboard: async ({dispatch}, data) => {
            try {
                const response = await api.addQueuesToWallboard(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeQueuesFromWallboard: async ({dispatch}, data) => {
            try {
                const response = await api.removeQueuesFromWallboard(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        updateWallboard: async ({dispatch}, data) => {
            try {
                const response = await api.updateWallboard(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        removeWallboards: async ({dispatch}, data) => {
            try {
                const response = await api.removeWallboards(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetWallboard: ({commit}) => commit(mutationTypes.RESET_WALLBOARD),
        duplicateWallboard: async ({dispatch}, data) => {
            try {
                const response = await api.duplicateWallboard(data)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
    },
    modules: {
        cards
    },
    mutations: {
        [mutationTypes.SET_WALLBOARD]: (state, data) => state.wallboard = data,
        [mutationTypes.RESET_WALLBOARD]: state => Object.assign(state, initialState())
    }
}
