import {findWhere} from 'underscore'

export const SUNDAY = {
    text: 'Sunday',
    value: 0
}

export const MONDAY = {
    text: 'Monday',
    value: 1
}

export const TUESDAY = {
    text: 'Tuesday',
    value: 2
}

export const WEDNESDAY = {
    text: 'Wednesday',
    value: 3
}

export const THURSDAY = {
    text: 'Thursday',
    value: 4
}

export const FRIDAY = {
    text: 'Friday',
    value: 5
}

export const SATURDAY = {
    text: 'Saturday',
    value: 6
}

export const DAYS_OF_WEEK = [
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
]

export function dayOfWeek (value) {
    return findWhere(DAYS_OF_WEEK, {value})
}

export default DAYS_OF_WEEK
