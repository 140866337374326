import client from '@/http'

/**
 * Extensions API endpoints
 */
export default {
    // Extensions API
    async searchExtensions (data) {
        try {
            const response = await client.get('/contact-centre/extensions', {params: data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    // All Extensions API
    async searchFullExtensions (data) {
        try {
            data.per_page=100;
            const response = await client.get('/contact-centre/extensions', {params: data})

            let extensions = response.data

            if (response.data.pagination.page_count > 1) {
                for (let indexPage = 2; indexPage < response.data.pagination.page_count + 1; indexPage++) {
                    const responsePage = await client.get('/contact-centre/extensions', {
                        params: {...data, page: indexPage}
                    })

                    extensions = {
                        pagination: responsePage.pagination,
                        data: [ ...extensions.data, ...responsePage.data.data]
                    }
                }
            }

            return extensions
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async searchUnassignedExtensions (data) {
        try {
            const response = await client.get('/contact-centre/extensions/unassigned-numbers', {params: data})
            return {data: response.data.extensions}
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
