import * as mutationTypes from './mutation-types'

const initialState = () => ({
    auth: null,
    authError: null,
    connected: null,
    loading: false,
    authenticated: false
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        auth: state => state.auth,
        isAuthed: state => state.authenticated,
        authError: state => state.authError,
        connected: state => state.connected,
        loading: state => state.loading
    },
    actions: {
        resetWebsocket: async ({commit}) => commit(mutationTypes.RESET_WEBSOCKET),
        setAuth: async ({commit}, data) => {
            commit(mutationTypes.SET_WEBSOCKET_AUTH, data)
        }
    },
    mutations: {
        [mutationTypes.RESET_WEBSOCKET]: state => Object.assign(state, initialState()),
        [mutationTypes.RESET_WEBSOCKET_AUTH] (state) {
            state.loading = false
            state.auth = null
            state.authError = null
        },
        [mutationTypes.SET_WEBSOCKET_AUTH] (state, data) {
            state.loading = true
            state.auth = data
        },
        [mutationTypes.SET_WEBSOCKET_AUTHENTICATED] (state, data) {
            state.authenticated = data
        },
        [mutationTypes.SET_WEBSOCKET_AUTH_ERROR] (state, data) {
            state.loading = false
            state.authError = data
        },
        [mutationTypes.SET_CONNECTED]: (state, data) => {
            state.connected = data
            if (state.connected) {
                state.authError = null
            }
        }
    }
}
