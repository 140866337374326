import {omit} from 'underscore'
import agent from './modules/agent'
import client from '@/http'
import qs from 'qs'

/**
 * Centre Agents API endpoints
 */
export default {
    // Modules
    agent,
    // Centre Agents API
    async listAgents (data) {
        try {
            let request = omit(data, ['extension', 'name']) || {}
            request.search = {}

            if (data?.extension) {
                request.search['extension.short_number'] = data.extension
            }

            if (data?.name) {
                request.search.name = data.name
            }

            const response = await client.get(`/contact-centre/agents?${qs.stringify(request)}`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async listFullAgents (data) {
        try {
            let request = omit(data, ['extension', 'name']) || {}
            request.search = {}
            request.per_page = 100
            
            if (data?.extension) {
                request.search['extension.short_number'] = data.extension
            }
            
            if (data?.name) {
                request.search.name = data.name
            }

            const response = await client.get(`/contact-centre/agents?${qs.stringify(request)}`)
            let agents = response.data

            if (response.data.pagination.page_count > 1) {
                for (let indexPage = 2; indexPage < response.data.pagination.page_count + 1; indexPage++) {
                    const responsePage = await client.get(`/contact-centre/agents?${qs.stringify(request)}` , {
                        params: {...data, page: indexPage}
                    })

                    agents = {
                        pagination: responsePage.pagination,
                        data: [ ...agents.data, ...responsePage.data.data]
                    }
                }
            }

            return agents
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
