export const AGENTS = 'AGENTS'
export const AGENTS_CREATE = 'AGENTS_CREATE'
export const AGENTS_DISABLE = 'AGENTS_DISABLE'
export const AGENTS_ENABLE = 'AGENTS_ENABLE'
export const AGENTS_REMOVE = 'AGENTS_REMOVE'
export const AGENTS_BULK_DISABLE = 'AGENTS_BULK_DISABLE'
export const AGENTS_BULK_ENABLE = 'AGENTS_BULK_ENABLE'
export const AGENTS_BULK_REMOVE = 'AGENTS_BULK_REMOVE'
export const AGENTS_UPDATE = 'AGENTS_UPDATE'
export const AGENTS_UPDATE_PRESENCE = 'AGENTS_UPDATE_PRESENCE'

// Queues
export const AGENT_QUEUES = 'AGENT_QUEUES'
export const AGENT_QUEUES_ADD = 'AGENT_QUEUES_ADD'
export const AGENT_QUEUES_CREATE = 'AGENT_QUEUES_CREATE'
export const AGENT_QUEUES_SETTINGS = 'AGENT_QUEUES_SETTINGS'
export const AGENT_QUEUES_REMOVE = 'AGENT_QUEUES_REMOVE'
export const AGENT_QUEUES_BULK_REMOVE = 'AGENT_QUEUES_BULK_REMOVE'

export default {
    list: AGENTS,
    create: AGENTS_CREATE,
    disable: AGENTS_DISABLE,
    enable: AGENTS_ENABLE,
    bulkDisable: AGENTS_BULK_DISABLE,
    bulkEnable: AGENTS_BULK_ENABLE,
    bulkRemove: AGENTS_BULK_REMOVE,
    remove: AGENTS_REMOVE,
    update: AGENTS_UPDATE,
    updatePresence: AGENTS_UPDATE_PRESENCE,
    queues: {
        list: AGENT_QUEUES,
        add: AGENT_QUEUES_ADD,
        create: AGENT_QUEUES_CREATE,
        remove: AGENT_QUEUES_REMOVE,
        bulkRemove: AGENT_QUEUES_BULK_REMOVE,
        settings: AGENT_QUEUES_SETTINGS
    }
}
