import * as mutationTypes from './mutation-types'
import api from '@/store/api'
// Modules
import extension from './modules/extension'

const initialState = () => ({
    extensions: {
        data: [],
        pagination: {}
    },
    extensionsSSO: {
        data: [],
        pagination: {}
    }
})

export default {
    namespaced: true,
    state: initialState(),
    getters: {
        extensions: state => state.extensions,
        extensionsSSO: state => state.extensionsSSO
    },
    actions: {
        listExtensions: async ({commit, dispatch}, data) => {
            try {
                const response = await api.cloudTelephony.extensions.listExtensions(data)
                commit(mutationTypes.SET_EXTENSIONS, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        listExtensionsWithSSO: async ({commit, dispatch}, data) => {
            try {
                const response = await api.cloudTelephony.extensions.listExtensionsWithSSO(data)
                commit(mutationTypes.SET_EXTENSIONS_SSO, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetExtensions: ({commit}) => commit(mutationTypes.RESET_EXTENSIONS)
    },
    mutations: {
        [mutationTypes.SET_EXTENSIONS]: (state, data) => state.extensions = data,
        [mutationTypes.SET_EXTENSIONS_SSO]: (state, data) => state.extensionsSSO = data,
        [mutationTypes.RESET_EXTENSIONS]: state => Object.assign(state, initialState())
    },
    modules: {
        extension
    }
}
