import client from '@/http'
import cards from './modules/cards'

/**
 * Centre Wallboard API endpoints
 */
export default {
    // Modules
    cards,
    // Centre Wallboard API
    /**
     * @param {{uuid: string}} data
     */
    async showWallboard (data) {
        try {
            const response = await client.get(`/contact-centre/wallboards/${data.uuid}`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{name: string}} data
     */
    async createWallboard (data) {
        try {
            const response = await client.post('/contact-centre/wallboards', data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuid: string, data: {name: string}}} data
     */
    async updateWallboard (data) {
        try {
            const response = await client.put(`/contact-centre/wallboards/${data.uuid}`, data.data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuids: string[]}} data
     */
    async removeWallboards (data) {
        try {
            const response = await client.delete(`/contact-centre/wallboards`, {data})
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuid: string, queue_uuids: array}} data
     */
    async addQueuesToWallboard (data) {
        try {
            const response = await client.post(
                `/contact-centre/wallboards/${data.uuid}/queues`,
                {uuids: data.queue_uuids}
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuid: string, queue_uuids: array}} data
     */
    async removeQueuesFromWallboard (data) {
        try {
            const response = await client.delete(
                `/contact-centre/wallboards/${data.uuid}/queues`,
                {data: {uuids: data.queue_uuids}}
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{uuid: string, data: {name: string}}} data
     */
    async duplicateWallboard (data) {
        try {
            const response = await client.post(`/contact-centre/wallboards/${data.uuid}/duplicate`, data.data)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
}
