import * as mutationTypes from './mutation-types'
import {mapPagination} from '@core/config/tables'
import apiModule from '@/store/api'
// Modules
import queue from './modules/queue'

const api = apiModule.cloudTelephony.contactCentre.centre.queues

const initialState = () => ({
    queuesSearch: {
        data: [],
        pagination: {}
    },
    queues: {
        data: [],
        pagination: {}
    }
})

export default {
    namespaced: true,
    state: initialState(),
    modules: {
        queue
    },
    getters: {
        queues: state => state.queues
    },
    actions: {
        listQueues: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listQueues(data)
                commit(mutationTypes.SET_QUEUES, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        searchQueues: async ({commit, dispatch}, data) => {
            try {
                const response = await api.listQueues(data)
                commit(mutationTypes.SET_QUEUES_SEARCH, response)
                return response
            } catch (e) {
                const errorResponse = await dispatch('errors/addErrors', [e], {root: true})
                return Promise.reject(errorResponse[0])
            }
        },
        resetQueuesSearch: ({commit}) => commit(mutationTypes.RESET_QUEUES_SEARCH),
        resetQueues: ({commit}) => commit(mutationTypes.RESET_QUEUES)
    },
    mutations: {
        [mutationTypes.SET_QUEUES]: (state, data) => {
            state.queues.data = data.data
            state.queues.pagination = mapPagination(data.pagination)
        },
        [mutationTypes.RESET_QUEUES]: state => Object.assign(state, initialState()),
        [mutationTypes.SET_QUEUES_SEARCH]: (state, data) => state.queuesSearch = data,
        [mutationTypes.RESET_QUEUES_SEARCH]: state => state.queuesSearch = initialState().queuesSearch
    }
}
