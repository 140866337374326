import client from '@/http'

/**
 * Centre Wallboard Card API endpoints
 */
export default {
    // Centre Wallboard Card API
    /**
     * @param {{wallboardUuid: string, uuid: string}} data
     */
    async showWallboardCard (data) {
        try {
            const response = await client.get(
                `/contact-centre/wallboards/${data.wallboardUuid}/cards/${data.uuid}`
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{
     *  wallboardUuid: string,
     *  data: {
     *      title: string,
     *      values: {crisis: number, actionable_bad: number, actionable_good: number, good: number},
     *      stat_unit: string,
     *      stat_name: string,
     *      disable_thresholds: boolean
     *  }
     * }} data
     */
    async createWallboardCard ({wallboardUuid, data}) {
        try {
            const response = await client.post(
                `/contact-centre/wallboards/${wallboardUuid}/cards`,
                data
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{
     * wallboardUuid: string,
     * uuid: string,
     * data: {
     *  name: string,
     *  values: {
     *      crisis: number,
     *      actionable_bad: number,
     *      actionable_good: number,
     *      good: number
     * }}}} data
     */
    async updateWallboardCard ({wallboardUuid, uuid, data}) {
        try {
            const response = await client.put(
                `/contact-centre/wallboards/${wallboardUuid}/cards/${uuid}`,
                data
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{wallboardUuid: string,order: string[]}} data
     */
    async reorderWallboardCards ({wallboardUuid, order}) {
        try {
            const response = await client.put(
                `/contact-centre/wallboards/${wallboardUuid}/cards`,
                {order}
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    },
    /**
     * @param {{wallboardUuid: string, uuids: string[]}} data
     */
    async removeWallboardCards ({wallboardUuid, uuids}) {
        try {
            const response = await client.delete(
                `/contact-centre/wallboards/${wallboardUuid}/cards`,
                {data: {uuids}}
            )
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
