import queue from './modules/queue'
import client from '@/http'
import qs from 'qs'
import {omit} from 'underscore'

/**
 * Centre Queues API endpoints
 */
export default {
    // Modules
    queue,
    // Centre Queues API
    async listQueues (data) {
        try {
            let request = omit(data, ['extension', 'name']) || {}
            request.search = {}

            if (data?.extension) {
                request.search.extension = data.extension
            }

            if (data?.name) {
                request.search.name = data.name
            }

            const response = await client.get(`/contact-centre/queues?${qs.stringify(request)}`)
            return response.data
        } catch (e) {
            return Promise.reject(e)
        }
    }
}
